import { composeRoutes, composeTabs } from "@/services/routerUtils";

const title = "Desenvolvimento";
const icon = "developer_board";
const permissionTag = "desenvolvedor";
const base = "desenvolvimento";

export const resumo = {
  name: `${base}Resumo`,
  tabTitle: "Resumo",
  path: "resumo",
  title,
  component: () => import("./resumo.vue"),
};

export const registros = {
  name: `${base}Registros`,
  tabTitle: "Registros",
  path: "registros",
  title,
  component: () => import("./registros.vue"),
};

export const gerarTitulos = {
  name: `${base}GerarTitulos`,
  tabTitle: "Gerar Títulos",
  path: "gerar-titulos",
  title,
  component: () => import("./gerar-titulos.vue"),
};

const sequence = [resumo, registros, gerarTitulos];

export const tabs = composeTabs(sequence);
export const navbarLinks = [{ title, icon, permissionTag, name: sequence[0].name }];
export const routes = composeRoutes(sequence, { permissionTag }, "/desenvolvimento/");
