<template>
  <main class="searchtable">
    <slot name="search" v-if="fieldsWithFieldAttrs.length">
      <SearchForm
        :fields="fieldsWithFieldAttrs"
        :value="filter"
        @input="(f) => $emit('update:filter', f)"
      />
    </slot>
    <form @submit.prevent="onSubmit" class="process-form">
      <slot name="process">
        <ModelDataTable
          :columns="columns"
          :dataset="dataset"
          :selected="selected"
          @update:selected="updateSelected"
          :children-selectable="childrenSelectable"
          :order-column="filter.orderBy"
          @update:order-column="updateFilter('orderBy', $event)"
          :class-list="classList"
          :track-by="trackBy"
          :progress="progress"
          :expanded-row="expandedRow"
          :children-prop="childrenProp"
          :children-columns="childrenColumns"
          :children-track-by="childrenTrackBy"
          :selected-index.sync="selectedIndex"
          :errors="errors"
          ref="table"
          v-on="$listeners"
          :shadow-size="shadowSize"
          v-bind="$attrs"
        >
          <template v-for="(slot, key) in $scopedSlots" v-slot:[key]="slotProps">
            <slot v-bind="slotProps" :name="key" />
          </template>
        </ModelDataTable>
      </slot>
      <slot name="bottom" />
      <Actions v-if="selected || actions.length > 0">
        <template
          v-for="{ id, back, label, enabled, disabled, color, tooltip, type } in trackedActions"
        >
          <template v-if="back">
            <TextButton
              :key="id"
              :color="color"
              @click="type !== 'submit' && $emit(`click:${id}`, $event)"
              :enabled="enabled"
              :disabled="disabled"
              data-tooltip-ref="bottom"
              v-tooltip="tooltip"
              :type="type"
            >
              {{ label }}
            </TextButton>
          </template>
        </template>
        <div class="upper" v-if="selected">
          {{ selectedText }}
        </div>
        <slot name="error" />
        <div
          :key="k"
          class="upper error-container"
          v-for="[k, e] in foundErrors"
          :class="e.class"
          @click="errorsPopup[k] = !errorsPopup[k]"
        >
          <span v-waves>
            {{ e.oneMessage }}
            <div class="lgn-triangle" :class="!errorsPopup[k] ? 'inverted' : ''" />
          </span>
          <div class="error-popup" v-show="errorsPopup[k]">
            <div class="item" v-waves @click="flashOnMatchSelected(e.check)">
              Ir para o primeiro erro
            </div>
            <div class="item" v-waves v-if="selected" @click="deselectMatch(e)">
              Deselecionar aqueles com erro
            </div>
          </div>
        </div>
        <div class="spacer" />
        <template
          v-for="{ id, back, label, enabled, disabled, color, tooltip, type } in trackedActions"
        >
          <template v-if="!back">
            <TextButton
              :key="id"
              :color="color"
              @click="type !== 'submit' && $emit(`click:${id}`, $event)"
              :enabled="enabled"
              :disabled="disabled"
              data-tooltip-ref="bottom"
              v-tooltip="tooltip"
              :type="type"
            >
              {{ label }}
            </TextButton>
          </template>
        </template>
      </Actions>
      <slot name="dialog" />
    </form>
  </main>
</template>

<script lang="ts">
import {
  reactive,
  SetupContext,
  ref,
  defineComponent,
  computed,
  watch,
  watchEffect,
} from "@vue/composition-api";
import Vue from "vue";

import { queueError } from "@/services/message-queue";
import { pick, debounce } from "@/services/utils";
import { s as sFilter } from "@/filters";
import { api } from "@/services/api";
import { Actions } from "@/services/useProcessScreen";

import SearchForm from "./SearchForm.vue";
import ModelDataTable from "./ModelDataTable.vue";

function pluralize(name: string | ((s: string | null) => string)) {
  return typeof name === "function"
    ? name("s")
    : `${name.split(" ")[0]}s ${name.split(" ").slice(1).join(" ")}`.trim();
}
type ErrorOption = {
  check: (row: { [key: string]: any }, rest?: { [key: string]: any }) => boolean;
  checkChild: (row: { [key: string]: any }, rest?: { [key: string]: any }) => boolean;
};

export default defineComponent({
  inheritAttrs: false,
  components: { SearchForm, ModelDataTable },

  props: {
    shadowSize: { type: Number, default: 8 },
    expandedRow: { type: Object },
    fields: { type: Array },
    datasetName: { type: Function, default: (s: string | null) => `Linha${s}` },
    datasetGender: { type: String },
    childrenName: { type: Function },
    errors: { type: Object, default: Object },
    actions: { type: Array, default: Array },
    childrenProp: { type: [String, Function] },
    childrenTrackBy: { type: String },
    childrenSelectable: { type: Boolean },
    columns: { type: Array, default: Array },
    classList: { type: [Array, Object, Function] },
    trackBy: { type: [String, Function], default: "id" },
    childrenColumns: { type: Array },
    searchUrl: String,
    selected: { type: Array },
    dataset: { type: Array, default: Array, required: true },
    searchBlocked: { type: Boolean, default: false },
    queryString: { type: null },

    dataMapper: {
      type: Function,
      default(data: Array<{ [key: string]: any }>, childrenProp?: string | ((i: any) => any)) {
        data.forEach((item) => {
          item.original_ = { ...item };
          if (childrenProp) {
            if (typeof childrenProp !== "string") {
              childrenProp(item).forEach((child: any) => {
                child.original_ = { ...child };
              });
            } else {
              item[childrenProp].forEach((child: any) => {
                child.original_ = { ...child };
              });
            }
          }
        });
        return data;
      },
    },
    filter: { type: Object, default: Object },
  },

  model: { prop: "dataset", event: "update:dataset" },

  setup(
    props: {
      actions: Actions<unknown>;
      childrenSelectable?: boolean;
      searchUrl: string;
      dataMapper: <T, U>(dataset: Array<T>, childrenProp?: string | ((i: any) => any)) => Array<U>;
      searchBlocked?: boolean;
      queryString: { [key: string]: any } | null;
      errors: { [key: string]: ErrorOption };
      dataset: Array<any>;
      columns: Array<{
        total?: () => any;
      }>;
      fields: Array<
        {
          id: number;
          type?: string | typeof Array;
          options?: Array<any>;
        } & {
          [key: string]: any;
        }
      >;
      filter: {
        [key: string]: any;
      };
      selected?: Array<
        | { [key: string]: any }
        | { row: { [key: string]: any }; childrenIndex: { [key: string]: any } }
      >;
      datasetName: string | ((s: string | null) => string);
      datasetGender?: string;
      childrenName?: string | ((s: string | null) => string);
      childrenProp?: string | ((s: string | null) => string);
    },
    context: SetupContext,
  ) {
    const { emit } = context;

    props.actions.forEach((action) => {
      if (!context.listeners[`click:${action.id}`]) {
        console.warn(`[process-screen] Missing handler for action ${action.id}`);
      }
    });

    const computedQueryString = computed(() => {
      return (
        props.queryString ||
        pick({
          ...(props.fields &&
            props.fields.reduce((obj, { id, type }) => {
              const requestId =
                type instanceof Array || (typeof type === "string" && type[0] === "*")
                  ? `${id}:bt`
                  : id;
              obj[requestId] = props.filter[id];
              return obj;
            }, {} as { [key: string]: any })),
          orderBy: props.filter.orderBy,
        })
      );
    });

    const fieldsWithFieldAttrs = computed(() => {
      const fieldMap = Object.create(null) as { [key: string]: typeof props.fields[0] };
      if (!props.fields) {
        return [];
      }
      props.fields.forEach((field) => {
        fieldMap[field.id] = field;
      });
      Object.keys(context.attrs).forEach((key) => {
        if (key.startsWith("field-")) {
          const firstDash = key.indexOf("-");
          const secondDash = key.indexOf("-", firstDash + 1);

          const id = key.slice(firstDash + 1, secondDash);
          const attr = key.slice(secondDash + 1);

          fieldMap[id][attr] = context.attrs[key];
        }
      });
      return Object.values(fieldMap).map((field) => {
        if (field.options) {
          return {
            ...field,
            options: [...(field.noAll ? [] : [{ id: null, text: "Tudo" }]), ...field.options],
          };
        }
        return field;
      });
    });

    const selectedText = computed(() => {
      if (!props.selected) {
        return "";
      }

      const name = props.datasetName;
      const value = props.selected.length;

      const singularName = typeof name === "function" ? name("") : name;
      const gender = props.datasetGender ?? (singularName.slice(-1) === "a" ? "a" : "");
      const none = gender === "a" ? "Nenhuma" : "Nenhum";
      const selected = gender === "a" ? "selecionada" : "selecionado";
      const pluralizedName = pluralize(name);

      let firstPart;
      if (value === 0) {
        firstPart = `${none} ${singularName}`;
      } else if (value === 1) {
        firstPart = `1 ${singularName}`;
      } else {
        firstPart = `${value} ${pluralizedName}`;
      }

      if (!props.childrenProp) {
        return `${firstPart} ${value > 1 ? `${selected}s` : selected}`;
      }

      const children = props.selected
        .map((x) => Object.keys(x.childrenIndex).length)
        .reduce((x, y) => x + y, 0);

      if (children === 0) {
        return `${firstPart} ${value > 1 ? `${selected}s` : selected}`;
      }

      const { childrenName } = props;
      const singularChildrenName =
        typeof childrenName === "function" ? childrenName("") : (childrenName as string);
      const pluralizedChildrenName = pluralize(
        childrenName as Exclude<typeof childrenName, undefined>,
      );
      const genderChildrenGuess = singularChildrenName.slice(-1) === "a" ? "a" : "";
      const selectedChildren = genderChildrenGuess === "a" ? "selecionada" : "selecionado";

      const secondPart =
        children === 1 ? `1 ${singularChildrenName}` : `${children} ${pluralizedChildrenName}`;

      return `${firstPart}, ${secondPart} ${
        children > 1 ? `${selectedChildren}s` : selectedChildren
      }`;
    });

    const foundErrors = computed(() => {
      const selected = props.selected || props.dataset;

      if (props.childrenProp && props.selected === selected) {
        return Object.entries(props.errors).filter(([, e]) =>
          props.selected?.some(({ row, ...rest }) => e.check(row, rest)),
        );
      }

      return Object.entries(props.errors).filter(([, e]) => selected.some((it) => e.check(it)));
    });

    const trackedActions = computed(() => {
      return props.actions.map((action) => {
        if (!action.label) {
          return action;
        }
        const trackArray = action.trackArray
          ? (context.attrs[action.trackArray] as any as Array<any>)
          : props.selected || props.dataset;

        return {
          id: action.id,
          color: action.class ?? "secondary",
          back: action.back,
          enabled: action.independent || trackArray.length > 0,
          disabled: !!(
            context.attrs[`action-${action.id}-disabled`] ||
            (action.disabled
              ? action.disabled(trackArray)
              : !action.independent &&
                (foundErrors.value.length > 0 || (action.single && trackArray.length > 1)))
          ),
          label: action.label(sFilter(trackArray), trackArray),
          tooltip: action.tooltip && action.tooltip(trackArray),
          type: action.type,
        };
      });
    });

    const errorsPopup = reactive({});

    for (const key of Object.keys(props.errors)) {
      Vue.set(errorsPopup, key, false);
    }

    const table = ref(
      null as null | {
        flash: (row: { [key: string]: any }) => void;
        highlight: (row: { [key: string]: any }) => void;
        setChildrenState: (row: { [key: string]: any }, state: number) => void;
      },
    );
    const abortController = ref(null as null | AbortController);

    const selectedIndex = ref(props.childrenProp ? Object.create(null) : null);

    const progress = ref(false);

    function highlightRow(row: { [key: string]: any }) {
      const tableValue = table.value;
      if (tableValue) tableValue.highlight(row);
    }

    const search = debounce(async function search() {
      if (props.searchBlocked) {
        return;
      }
      const abortControllerValue = abortController.value;
      if (abortControllerValue) {
        abortControllerValue.abort();
      }

      progress.value = true;
      emit("update:progress", true);

      try {
        abortController.value = new window.AbortController();
        const res = await api.getSignal(
          props.searchUrl.replace(/:([A-z]+)/, (match, key) => props.filter[key]),
          abortController.value.signal,
          computedQueryString.value,
        );

        const mappedData = props.dataMapper(res.data as Array<unknown>, props.childrenProp);
        emit("update:dataset", mappedData);
      } catch (e) {
        if ((e as { name: string }).name === "AbortError") {
          return;
        }
        queueError(e as Error);
      } finally {
        progress.value = false;
        emit("update:progress", false);
      }
    }, 205 /* just a little bit slower than 60 WPM (1 / ((60*5)/60)) */);

    watch(() => props.filter, search, { deep: true, immediate: true });
    watch(() => props.searchBlocked, search);
    watchEffect(() => {
      emit("update:queryString", computedQueryString.value);
      emit("update:query-string", computedQueryString.value);
    });

    return {
      computedQueryString,
      fieldsWithFieldAttrs,
      selectedText,
      foundErrors,
      trackedActions,

      table,

      selectedIndex,

      progress,

      errorsPopup,

      onSubmit() {
        emit(`click:${props.actions.find((x) => x.type === "submit")?.id}`);
      },

      updateFilter(id: string, value: any) {
        emit("update:filter", { ...props.filter, [id]: value });
      },

      flashOnMatchSelected(fn: (row: { [key: string]: any }) => boolean) {
        const selected = props.selected || props.dataset;
        if (props.childrenProp && selected === props.selected) {
          highlightRow(props.selected.map((x) => x.row).find(fn));
        } else {
          const found = selected.find(fn);
          if (found) highlightRow(found);
        }
      },
      updateSelected(newSelected: any) {
        emit("update:selected", newSelected);
      },

      deselectMatch(error: ErrorOption) {
        const fn = error.check;
        if (props.childrenProp) {
          if (props.childrenSelectable) {
            const fnChild = error.checkChild;

            for (const k of Object.keys(selectedIndex.value)) {
              for (const childK of Object.keys(selectedIndex.value[k].childrenIndex)) {
                const child = selectedIndex.value[k].childrenIndex[childK];
                if (!fnChild(child)) {
                  const tableValue = table.value;
                  if (tableValue) tableValue.setChildrenState(child, 0);
                }
              }
            }
          } else {
            const newSelection = Object.create(null);

            Object.keys(selectedIndex.value).forEach((k) => {
              if (!fn(selectedIndex.value[k].row)) {
                newSelection[k] = selectedIndex.value[k];
              }
            });

            selectedIndex.value = newSelection;
            emit("update:selected", Object.values(newSelection));
          }
        } else {
          emit(
            "update:selected",
            (props.selected || []).filter((x) => !fn(x)),
          );
        }
      },

      search,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "~ligno/src/shadows";

td .lgn-picker {
  min-width: 96px;
}

.error-popup {
  transition: 0.2s all;
  position: absolute;
  bottom: 32px;
  left: -8px;
  z-index: 100;
  background: white;
  box-shadow: $whiteframe-shadow-1dp;

  > .item {
    padding: 16px;
    height: 48px;
    width: 100%;
  }
}

.error-container {
  position: relative;

  > span {
    display: flex;
    align-items: center;
    margin: -8px;
    padding: 8px;
  }

  .lgn-triangle {
    margin-left: 4px;
    transition: 0.2s transform;
  }
  .inverted {
    transform: rotate(180deg);
  }
}

.lgn-actions {
  height: 48px;
}
</style>
