<template>
  <component
    :is="childrenProp ? 'TreeDataTable' : 'DataTable'"
    :columns="columns"
    :value="dataset"
    :selected="selected"
    @update:selected="updateSelected"
    :children-selectable="childrenSelectable"
    :order-column="orderColumn"
    @update:order-column="updateOrderColumn"
    :class-list="classList"
    :track-by="trackBy"
    :progress="progress"
    :expanded-row="expandedRow"
    :children-prop="childrenProp"
    :children-columns="childrenColumns"
    :children-track-by="childrenTrackBy"
    :selected-index="selectedIndex"
    @update:selected-index="updateSelectedIndex"
    ref="table"
    v-on="$listeners"
    :shadow-size="shadowSize"
  >
    <template #[rootRow]="row">
      <template v-for="column in columns">
        <td
          v-if="!column.ignore"
          :key="column.id"
          :colspan="column.span"
          :class="[
            column.type,
            column.align,
            column.ellipsis && 'ellipsis',
            column.class && column.class(row),
          ]"
        >
          <slot
            v-if="$scopedSlots[`column-${column.id}`]"
            :name="`column-${column.id}`"
            v-bind="row"
            :row="row"
          />
          <Field
            v-else-if="
              typeof column.editable === 'function' ? column.editable(row) : column.editable
            "
            v-model="row[column.id]"
            @input="selectIfNot(row), $emit('input-editable', $event, row, column.id, false)"
            :original="row.original_[column.id]"
            in-table
            font="14px Roboto"
            :key="
              row.$key ||
              (typeof trackBy === 'string' ? row[trackBy] : trackBy(row)) + '+' + column.id
            "
            v-bind="filterColumnAttrs(column, row)"
          />
          <span
            v-else
            :class="[
              column.class && column.class(row),
              errors[column.id] && errors[column.id].check(row) ? errors[column.id].class : '',
            ]"
            v-tooltip="
              (column.rowTooltip ? column.rowTooltip(row) : '') +
              (errors[column.id] && errors[column.id].check(row)
                ? errors[column.id].message(row)
                : '')
            "
          >
            {{
              column.get
                ? column.get(row)
                : column.filter
                ? column.filter(getPath(row, column.id), row)
                : getPath(row, column.id)
            }}
          </span>
        </td>
      </template>
    </template>

    <template #child="row">
      <td
        :key="column.id"
        v-for="column in childrenColumns"
        :colspan="column.span"
        :class="[column.align, column.class && column.class(row)]"
      >
        <slot
          v-if="$scopedSlots[`child-column-${column.id}`]"
          :name="`child-column-${column.id}`"
          v-bind="row"
          :row="row"
        />
        <Field
          v-else-if="typeof column.editable === 'function' ? column.editable(row) : column.editable"
          v-model="row[column.id]"
          @input="selectIfNot(row), $emit('input-editable', $event, row, column.id, true)"
          :original="row.original_[column.id]"
          in-table
          font="14px Roboto"
          :key="row.$key || row[childrenTrackBy] + '+' + column.id"
          v-bind="filterColumnAttrs(column, row)"
        />
        <span v-else v-tooltip="column.rowTooltip ? column.rowTooltip(row) : ''">
          {{
            column.get
              ? column.get(row)
              : column.filter
              ? column.filter(getPath(row, column.id), row)
              : getPath(row, column.id)
          }}
        </span>
      </td>
    </template>

    <template #total v-if="hasTotal">
      <td :key="column.id" v-for="column in columns" :class="column.align" :colspan="column.span">
        {{
          column.total &&
          (selected && selected.length ? column.total(selected) : column.total(dataset))
        }}
      </td>
    </template>

    <template #expand="{ row }" v-if="$scopedSlots.expand">
      <slot name="expand" :row="row" />
    </template>

    <template #counter="row" v-if="$scopedSlots.counter">
      <slot name="counter" v-bind="row" />
    </template>
  </component>
</template>

<script lang="ts">
import { ref, defineComponent, computed } from "@vue/composition-api";
import Field from "./Field.vue";

export default defineComponent({
  inheritAttrs: false,
  components: { Field },

  props: {
    childrenProp: { type: [String, Function] },
    childrenTrackBy: { type: String },
    columns: { type: Array, default: Array },
    dataset: { type: Array, default: Array, required: true },
    selected: { type: Array },
    childrenSelectable: { type: Boolean },
    orderColumn: { type: String },
    classList: { type: [Array, Object, Function] },
    trackBy: { type: [String, Function], default: "id" },
    progress: { type: Boolean },
    expandedRow: { type: Object },
    childrenColumns: { type: Array },
    selectedIndex: { type: null },
    shadowSize: { type: Number, default: 8 },
    errors: { type: Object, default: Object },
  },

  setup(props, context) {
    const { emit } = context;

    const table = ref(
      null as null | {
        highlight: (row: { [key: string]: any }) => void;
        setChildrenState: (row: { [key: string]: any }, state: number) => void;
      },
    );

    const hasTotal = computed(() => {
      return props.columns.some((x) => "total" in (x as Record<string, unknown>));
    });

    const rootRow = computed(() => {
      return props.childrenProp ? "root" : "row";
    });

    return {
      table,
      rootRow,
      hasTotal,
      updateSelected(newSelected: any) {
        emit("update:selected", newSelected);
      },
      updateOrderColumn(newOrderColumn: any) {
        emit("update:order-column", newOrderColumn);
      },
      updateSelectedIndex(newSelectedIndex: any) {
        emit("update:selected-index", newSelectedIndex);
      },
      highlight(row: { [key: string]: any }) {
        const tableValue = table.value;
        if (tableValue) tableValue.highlight(row);
      },
      setChildrenState(row: { [key: string]: any }, state: number) {
        const tableValue = table.value;
        if (tableValue) tableValue.setChildrenState(row, state);
      },
      getPath(obj: { [key: string]: any }, str: string) {
        return str && str.split(/\.|:/g).reduce((o, x) => o && o[x], obj);
      },
      selectIfNot(row: { [key: string]: any }) {
        if (props.selected) {
          if (props.childrenSelectable) {
            const tableValue = table.value;
            if (tableValue) tableValue.setChildrenState(row, 1);
          } else if (props.selected.indexOf(row) === -1) {
            emit("update:selected", [...props.selected, row]);
          }
        }
      },
      filterColumnAttrs(column: { [key: string]: any }, row: { [key: string]: any }) {
        const result = { ...column };
        Object.keys(context.attrs).forEach((key) => {
          if (key.startsWith(`column-${column.id}`)) {
            result[key.slice(`column-${column.id}-`.length)] = (
              context.attrs[key] as any as (r: { [key: string]: any }) => any
            )(row);
          }
        });
        return result;
      },
    };
  },
});
</script>
