import { composeRoutes } from "@/services/routerUtils";

const title = "Fornecedores";
const icon = "account-arrow-right";
const permissionTag = "cadastros";
const base = "fechamentos";

export const relacao = {
  name: `${base}Relacao`,
  path: "relacao",
  title,
  component: () => import("./relacao.vue"),
};

export const detalhe = {
  name: `${base}Detalhe`,
  path: ":id",
  title: "Fornecedor",
  component: () => import("./detalhe.vue"),
};

export const navbarLinks = [{ title, icon, permissionTag, name: relacao.name }];
export const routes = composeRoutes([relacao, detalhe], { permissionTag }, "/fornecedores/");
