<template>
  <Autocomplete
    :value="value"
    ref="input"
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    @update="update"
    @focus="onFocus"
  >
    <template #item="item">
      <slot name="item" v-bind="item" />
    </template>
    <template #not-found="{ textValue }">
      <slot name="not-found" :text-value="textValue" />
    </template>
    <template #extra="{ textValue }">
      <slot name="extra" :text-value="textValue" />
    </template>
  </Autocomplete>
</template>

<script lang="ts">
import { ref, defineComponent } from "@vue/composition-api";
import { queueError } from "@/services/message-queue";
import { api } from "@/services/api";

export default defineComponent({
  inheritAttrs: false,
  props: {
    searchUrl: { type: String, required: true },
    queryParams: null,
    value: null,
    queryWhenEmpty: Boolean,
  },
  setup(props) {
    const abortController = ref(null as AbortController | null);
    const items = ref(null as null | Array<unknown>);
    const input = ref(null as null | { reset(): void; focus(): void });

    async function update(q: string | null, cb: () => void) {
      const abortControllerValue = abortController.value;
      if (abortControllerValue) {
        abortControllerValue.abort();
      }

      if (!q && !props.queryWhenEmpty) {
        items.value = null;
        cb();
        return;
      }

      try {
        abortController.value = new window.AbortController();
        const res = await api.getSignal(props.searchUrl, abortController.value.signal, {
          q,
          ...props.queryParams,
        });
        items.value = res.data ? (res.data as Array<unknown>) : null;
      } catch (e) {
        if ((e as Error).name !== "AbortError") {
          queueError(e as Error);
        }
      }

      cb();
    }

    return {
      input,
      abortController,
      items,
      update,
      reset() {
        items.value = null;
        const inputValue = input.value;
        if (inputValue) inputValue.reset();
      },
      focus() {
        const inputValue = input.value;
        if (inputValue) inputValue.focus();
      },
      onFocus() {
        if (props.queryWhenEmpty)
          update(null, () => {
            // moot
          });
      },
    };
  },
});
</script>
