import { composeRoutes } from "@/services/routerUtils";

const permissionTag = "titulos-receber";

const lancamentoTitle = "Escolher Lançamento";
const base = "vinculacaoValoresEscolherLancamento";

export const escolherLancamento = {
  name: base,
  path: "escolher-lancamento",
  title: lancamentoTitle,
  component: () => import("./escolher-lancamento.vue"),
};

export const routes = composeRoutes(
  [escolherLancamento],
  { permissionTag },
  "combinar-lancamento/",
);
