import { routes as painelRoutes, navbarLinks as painelNavbarLinks } from "./painel/config";
import {
  routes as ocorrenciasRoutes,
  navbarLinks as ocorrenciasNavbarLinks,
} from "./ocorrencias/config";
import {
  routes as rastreamentoRoutes,
  navbarLinks as rastreamentoNavbarLinks,
} from "./rastreamento/config";
import { routes as trajetosRoutes, navbarLinks as trajetosNavbarLinks } from "./trajetos/config";
import {
  routes as cruzamentosRoutes,
  navbarLinks as cruzamentosNavbarLinks,
} from "./cruzamentos/config";
import { routes as usuariosRoutes, navbarLinks as usuariosNavbarLinks } from "./usuarios/config";
import { routes as tabeladasRoutes, navbarLinks as tabeladasNavbarLinks } from "./tabeladas/config";
import {
  routes as faturasTaxiDigitalRoutes,
  navbarLinks as faturasTaxiDigitalNavbarLinks,
} from "./faturas-taxi-digital/config";
import {
  routes as saldosTaxiDigitalRoutes,
  navbarLinks as saldosTaxiDigitalNavbarLinks,
} from "./saldos-taxi-digital/config";
import {
  routes as vinculacaoValoresRoutes,
  navbarLinks as vinculacaoValoresNavbarLinks,
} from "./vinculacao-valores/config";
import {
  routes as escrituracaoRoutes,
  navbarLinks as escrituracaoNavbarLinks,
} from "./escrituracao/config";
import {
  routes as titulosReceberRoutes,
  navbarLinks as titulosReceberNavbarLinks,
} from "./titulos/titulos-receber/config";
import {
  routes as titulosPagarRoutes,
  navbarLinks as titulosPagarNavbarLinks,
} from "./titulos/titulos-pagar/config";
import { routes as virtuaisRoutes, navbarLinks as virtuaisNavbarLinks } from "./virtuais/config";
import {
  routes as proximosPagamentosRoutes,
  navbarLinks as proximosPagamentosNavbarLinks,
} from "./proximos-pagamentos/config";
import {
  routes as conciliacaoContabilRoutes,
  navbarLinks as conciliacaoContabilNavbarLinks,
} from "./conciliacao-contabil/config";
import { routes as extratosRoutes, navbarLinks as extratosNavbarLinks } from "./extratos/config";
import {
  routes as impostoRendaRoutes,
  navbarLinks as impostoRendaNavbarLinks,
} from "./imposto-renda/config";
import {
  routes as fechamentosRoutes,
  navbarLinks as fechamentosNavbarLinks,
} from "./fechamentos/config";
import { routes as caixasRoutes, navbarLinks as caixasNavbarLinks } from "./caixas/config";
import {
  routes as fluxoCaixaRoutes,
  navbarLinks as fluxoCaixaNavbarLinks,
} from "./fluxo-caixa/config";
import { routes as cartoesRoutes, navbarLinks as cartoesNavbarLinks } from "./cartoes/config";
import {
  routes as chequesRecebidosRoutes,
  navbarLinks as chequesRecebidosNavbarLinks,
} from "./cheques-recebidos/config";
import {
  routes as chequesEmitidosRoutes,
  navbarLinks as chequesEmitidosNavbarLinks,
} from "./cheques-emitidos/config";
import { routes as boletosRoutes, navbarLinks as boletosNavbarLinks } from "./boletos/config";
import { routes as rateiosRoutes, navbarLinks as rateiosNavbarLinks } from "./rateios/config";
import {
  routes as desenvolvimentoRoutes,
  navbarLinks as desenvolvimentoNavbarLinks,
} from "./desenvolvimento/config";
import { routes as vouchersRoutes, navbarLinks as vouchersNavbarLinks } from "./vouchers/config";
import {
  routes as transferenciaRoutes,
  navbarLinks as transferenciaNavbarLinks,
} from "./transferencias/config";
import { routes as clientesRoutes, navbarLinks as clientesNavbarLinks } from "./clientes/config";
import {
  routes as fornecedoresRoutes,
  navbarLinks as fornecedoresNavbarLinks,
} from "./fornecedores/config";
import {
  routes as notasEntradaRoutes,
  navbarLinks as notasEntradaNavbarLinks,
} from "./notas-entrada/config";
import {
  routes as provisionamentoRoutes,
  navbarLinks as provisionamentoNavbarLinks,
} from "./provisionamento/config";

export const navbarLinks = [
  ...painelNavbarLinks,
  ...ocorrenciasNavbarLinks,
  ...rastreamentoNavbarLinks,
  ...trajetosNavbarLinks,
  ...cruzamentosNavbarLinks,
  ...usuariosNavbarLinks,
  ...tabeladasNavbarLinks,
  ...faturasTaxiDigitalNavbarLinks,
  ...escrituracaoNavbarLinks,
  ...titulosReceberNavbarLinks,
  ...titulosPagarNavbarLinks,
  ...virtuaisNavbarLinks,
  ...proximosPagamentosNavbarLinks,
  ...extratosNavbarLinks,
  ...vinculacaoValoresNavbarLinks,
  ...conciliacaoContabilNavbarLinks,
  ...provisionamentoNavbarLinks,
  ...transferenciaNavbarLinks,
  ...impostoRendaNavbarLinks,
  ...fechamentosNavbarLinks,
  ...caixasNavbarLinks,
  ...fluxoCaixaNavbarLinks,
  ...cartoesNavbarLinks,
  ...chequesRecebidosNavbarLinks,
  ...chequesEmitidosNavbarLinks,
  ...boletosNavbarLinks,
  ...rateiosNavbarLinks,
  ...desenvolvimentoNavbarLinks,
  ...vouchersNavbarLinks,
  ...clientesNavbarLinks,
  ...fornecedoresNavbarLinks,
  ...notasEntradaNavbarLinks,
  ...saldosTaxiDigitalNavbarLinks,
];

export const perfil = {
  name: "perfil",
  title: "Perfil",
};

export const login = {
  name: "login",
  title: "Login",
};

export const configuracao = {
  name: "configuracao",
  title: "Configuração",
};

export const routes = [
  {
    name: "main",
    path: "/",
    component: () => import("./main.vue"),
  },
  {
    name: "login",
    path: "/login",
    component: () => import("./login/main.vue"),
  },
  {
    name: perfil.name,
    path: "/perfil",
    component: () => import("./perfil.vue"),
  },
  {
    name: configuracao.name,
    path: "/configuracao",
    component: () => import("./configuracao.vue"),
  },
  ...painelRoutes,
  ...ocorrenciasRoutes,
  ...rastreamentoRoutes,
  ...trajetosRoutes,
  ...cruzamentosRoutes,
  ...usuariosRoutes,
  ...tabeladasRoutes,
  ...faturasTaxiDigitalRoutes,
  ...escrituracaoRoutes,
  ...titulosReceberRoutes,
  ...titulosPagarRoutes,
  ...virtuaisRoutes,
  ...proximosPagamentosRoutes,
  ...extratosRoutes,
  ...vinculacaoValoresRoutes,
  ...conciliacaoContabilRoutes,
  ...transferenciaRoutes,
  ...impostoRendaRoutes,
  ...fechamentosRoutes,
  ...caixasRoutes,
  ...fluxoCaixaRoutes,
  ...cartoesRoutes,
  ...chequesEmitidosRoutes,
  ...chequesRecebidosRoutes,
  ...boletosRoutes,
  ...rateiosRoutes,
  ...desenvolvimentoRoutes,
  ...vouchersRoutes,
  ...clientesRoutes,
  ...fornecedoresRoutes,
  ...notasEntradaRoutes,
  ...provisionamentoRoutes,
  ...saldosTaxiDigitalRoutes,
  { path: "*", redirect: "/" },
];
