import { Ref } from "@vue/composition-api";
import useRouter from "@/services/useRouter";

export function route(component: { [key: string]: any }, extra: { [key: string]: any } = {}) {
  return {
    name: extra.name || component.name,
    path:
      extra.path ||
      component.path ||
      (extra.name && `/${extra.name}`) ||
      (component.name && `/${component.name}`),
    props: !!component.props,
    component,
    meta: {
      permissionTag: extra.permissionTag || component.permissionTag,
      title: extra.title || component.title,
      icon: extra.icon || component.icon,
      partOf: component.partOf,
      group: component.group,
      ...extra,
    },
  };
}

export function mapChildren(name: any, components: Array<{ [key: string]: any }>) {
  return (components.map((r) => route(r)) as any).concat([
    { path: "", name, redirect: components[0].path },
  ]);
}

export function goBackWithDefault(defaultRouteName: string) {
  const { route: currentRoute, router } = useRouter();
  if (currentRoute.value.params.back) {
    router.replace(currentRoute.value.params.back);
  } else {
    router.replace({ name: defaultRouteName });
  }
}

export function composeRoutes(
  routes: Array<{
    name: string;
    path: string;
    title: string | Ref<string>;
    component: () => any;
  }>,
  { permissionTag }: { permissionTag: string },
  prefix: string,
) {
  return routes.map(({ name, path, title, component }) => ({
    name,
    path: prefix + path,
    meta: { permissionTag, title },
    component,
  }));
}

export function composeTabs(
  routes: Array<{
    name: string;
    tabDisabled?: Ref<boolean>;
    tabTitle: string;
  }>,
) {
  return routes.map(({ name, tabDisabled, tabTitle }) => ({
    name,
    disabled: tabDisabled,
    title: tabTitle,
  }));
}
