import { composeRoutes } from "@/services/routerUtils";

const title = "Imposto de Renda";
const icon = "percent";
const permissionTag = "imposto-renda";
const base = "impostoRenda";

export const impostoRenda = {
  name: base,
  title,
  path: "",
  component: () => import("./main.vue"),
};

export const navbarLinks = [{ title, icon, permissionTag, name: impostoRenda.name }];
export const routes = composeRoutes([impostoRenda], { permissionTag }, "/imposto-renda/");
