import { composeRoutes } from "@/services/routerUtils";

const permissionTag = "titulos-receber";
const base = "vinculacaoValoresCombinar";

export const combinar = {
  name: base,
  path: "",
  title: "Combinar Valores",
  component: () => import("./main.vue"),
};

const titulosReceberTitle = "Escolher Título(s) a Receber";

export const escolherTitulosReceber = {
  name: `${base}EscolherTitulosReceber`,
  path: "escolher-titulos-receber",
  title: titulosReceberTitle,
  component: () => import("./escolher-titulos-receber.vue"),
};

export const escolherTitulosReceberParcelas = {
  name: `${base}EscolherTitulosReceberParcelas`,
  path: "escolher-titulos-receber-parcelas",
  title: titulosReceberTitle,
  component: () => import("./escolher-titulos-receber-parcelas.vue"),
};

export const revisarReceber = {
  name: `${base}RevisarReceber`,
  path: "revisar-receber",
  title: titulosReceberTitle,
  component: () => import("./revisar-receber.vue"),
};

const titulosPagarTitle = "Escolher Título(s) a Pagar";

export const escolherTitulosPagar = {
  name: `${base}EscolherTitulosPagar`,
  path: "escolher-titulos-pagar",
  title: titulosPagarTitle,
  component: () => import("./escolher-titulos-pagar.vue"),
};

export const escolherTitulosPagarParcelas = {
  name: `${base}EscolherTitulosPagarParcelas`,
  path: "escolher-titulos-pagar-parcelas",
  title: titulosPagarTitle,
  component: () => import("./escolher-titulos-pagar-parcelas.vue"),
};

export const revisarPagar = {
  name: `${base}RevisarPagar`,
  path: "revisar-pagar",
  title: titulosPagarTitle,
  component: () => import("./revisar-pagar.vue"),
};

export const escolherTransferencia = {
  name: `${base}EscolherTransferencia`,
  path: "escolher-transferencia",
  title: "Escolher Lançamento Complementar",
  component: () => import("./escolher-transferencia.vue"),
};

export const routes = composeRoutes(
  [
    combinar,
    escolherTitulosReceber,
    escolherTitulosReceberParcelas,
    revisarReceber,
    escolherTitulosPagar,
    escolherTitulosPagarParcelas,
    revisarPagar,
    escolherTransferencia,
  ],
  { permissionTag },
  "combinar/",
);
