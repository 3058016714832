import { composeRoutes } from "@/services/routerUtils";

const title = "Tabeladas";
const icon = "table";
const permissionTag = "tabeladas";
const base = "tabeladas";

export const tabeladas = {
  name: base,
  title,
  path: "",
  component: () => import("./main.vue"),
  redirectF8: "/tabeladas",
};

export const navbarLinks = [{ title, icon, permissionTag, name: tabeladas.name }];
export const routes = composeRoutes([tabeladas], { permissionTag }, "/tabeladas/");
