<template>
  <main class="searchtable">
    <slot name="top" />
    <ProgressBar :value="progress" />
    <iframe
      title="Relatório em PDF"
      ref="frame"
      :type="url.endsWith('pdf') ? 'application/pdf' : 'text/html'"
    />
    <Actions>
      <TextButton color="secondary" @click="goBack"
        >Voltar {{ tableTitlePrefix }} {{ tableTitle }}</TextButton
      >
      <div class="spacer"></div>
      <slot name="extra-buttons" />
      <a
        v-if="xlsAvailable"
        class="lgn-textbutton secondary waves-effect"
        @click="downloadXLSX"
        @keypress.enter="downloadXLSX"
      >
        Baixar XLSX
      </a>
      <a
        class="lgn-textbutton waves-effect secondary"
        :class="!objectUrl && 'disabled'"
        :href="objectUrl"
        download="relatorio.pdf"
      >
        Baixar PDF
      </a>
      <TextButton color="secondary" @click="printBrowser">Imprimir no Navegador</TextButton>
    </Actions>
    <Dialog :active="warningDialog.active" @action="warningDialog.active = false">
      <div class="body">
        <div>O seu navegador não suporta essa funcionalidade por meio deste botão.</div>
        <div>Por favor utilize o botão imprimir disponível em cima do documento.</div>
      </div>
      <Actions>
        <TextButton color="secondary" type="submit">OK</TextButton>
      </Actions>
    </Dialog>
  </main>
</template>

<script lang="ts">
import Vue from "vue";
import { reactive, defineComponent, computed, watch, ref, onUnmounted } from "@vue/composition-api";
import { goBackWithDefault } from "@/services/routerUtils";
import useRouter from "@/services/useRouter";
import { api } from "@/services/api";
import { saveAs } from "file-saver";

export default defineComponent({
  name: "relatorio",

  props: {
    params: null,
    url: { required: true, type: String },
    tableRouteName: { required: true, type: String },
    tableTitle: { required: true, type: String },
    tableTitlePrefix: { type: String, default: "a" },
    xlsAvailable: Boolean,
  },

  setup(props) {
    const { route } = useRouter();
    const searchParams = computed(() => {
      return props.params || route.value.params;
    });

    const objectUrl = ref(null as null | string);

    const frame = ref(null as HTMLFrameElement | null);

    const progress = ref(false);
    async function search() {
      const objectUrlValue = objectUrl.value;
      if (objectUrlValue) {
        window.URL.revokeObjectURL(objectUrlValue);
      }
      progress.value = true;
      objectUrl.value = null;
      const { data } = await api.body("POST", props.url, [searchParams.value], {
        headers: { "X-Http-Method-Override": "GET" },
      });
      progress.value = false;
      const newObjectUrl = window.URL.createObjectURL(data as Blob);
      objectUrl.value = newObjectUrl;
      await Vue.nextTick();
      const frameValue = frame.value;
      if (frameValue?.contentWindow) frameValue.contentWindow.location.replace(newObjectUrl);
    }
    watch(() => searchParams, search, { immediate: true });
    watch(() => props.params, search);

    onUnmounted(() => {
      const objectUrlValue = objectUrl.value;
      if (objectUrlValue) window.URL.revokeObjectURL(objectUrlValue);
    });

    const warningDialog = reactive({
      active: false,
    });

    return {
      frame,
      warningDialog,
      objectUrl,
      search,
      progress,
      goBack() {
        goBackWithDefault(props.tableRouteName);
      },
      async downloadXLSX() {
        const { data, headers } = await api.body(
          "POST",
          `${props.url.slice(0, -4)}.xlsx`,
          [searchParams.value, { download: true }],
          { headers: { "X-Http-Method-Override": "GET" } },
        );
        const filename = headers.get("Content-Disposition")?.match(/filename="(.*)"/)?.[1];
        saveAs(new Blob([data as Blob], { type: "application/xlsx" }), filename);
      },
      printBrowser() {
        try {
          const frameValue = frame.value;
          if (frameValue?.contentWindow) frameValue.contentWindow.print();
        } catch (e) {
          warningDialog.active = true;
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
@import "~ligno/src/shadows";

.lgn-actions {
  background: #fff;
}

iframe {
  border: none;
  flex: 1;
  z-index: 1;
  background-color: rgb(64, 64, 64);
}

main {
  position: relative;
}

.lgn-progress {
  z-index: 9000;
}
</style>
