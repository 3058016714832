import { composeRoutes, composeTabs } from "@/services/routerUtils";

const title = "Boletos";
const icon = "barcode";
const permissionTag = "boletos";
const base = "boletos";

export const emitir = {
  name: `${base}Emitir`,
  tabTitle: "Emitir",
  path: "emitir",
  title,
  component: () => import("./emitir.vue"),
};

export const imprimir = {
  name: `${base}Imprimir`,
  tabTitle: "Imprimir\n e Gerar Remessa",
  path: "imprimir",
  title,
  component: () => import("./imprimir.vue"),
};

export const enviar = {
  name: `${base}Enviar`,
  tabTitle: "Remessas\n A Enviar",
  path: "enviar",
  title,
  component: () => import("./enviar.vue"),
};

export const importar = {
  name: `${base}Importar`,
  tabTitle: "Importar\n Remessas",
  path: "importar",
  title,
  component: () => import("./importar.vue"),
};

export const retornos = {
  name: `${base}RetornosRelacao`,
  tabTitle: "Retornos\n de Remessa",
  path: "retornos",
  title,
  component: () => import("./retornos.vue"),
};

const sequence = [emitir, imprimir, enviar, importar, retornos];

export const tabs = composeTabs(sequence);
export const navbarLinks = [{ title, icon, permissionTag, name: sequence[0].name }];
export const routes = composeRoutes(sequence, { permissionTag }, "/boletos/");
