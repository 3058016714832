var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.childrenProp ? 'TreeDataTable' : 'DataTable',_vm._g({ref:"table",tag:"component",attrs:{"columns":_vm.columns,"value":_vm.dataset,"selected":_vm.selected,"children-selectable":_vm.childrenSelectable,"order-column":_vm.orderColumn,"class-list":_vm.classList,"track-by":_vm.trackBy,"progress":_vm.progress,"expanded-row":_vm.expandedRow,"children-prop":_vm.childrenProp,"children-columns":_vm.childrenColumns,"children-track-by":_vm.childrenTrackBy,"selected-index":_vm.selectedIndex,"shadow-size":_vm.shadowSize},on:{"update:selected":_vm.updateSelected,"update:order-column":_vm.updateOrderColumn,"update:selected-index":_vm.updateSelectedIndex},scopedSlots:_vm._u([{key:_vm.rootRow,fn:function(row){return [_vm._l((_vm.columns),function(column){return [(!column.ignore)?_c('td',{key:column.id,class:[
          column.type,
          column.align,
          column.ellipsis && 'ellipsis',
          column.class && column.class(row) ],attrs:{"colspan":column.span}},[(_vm.$scopedSlots[("column-" + (column.id))])?_vm._t(("column-" + (column.id)),null,{"row":row},row):(
            typeof column.editable === 'function' ? column.editable(row) : column.editable
          )?_c('Field',_vm._b({key:row.$key ||
            (typeof _vm.trackBy === 'string' ? row[_vm.trackBy] : _vm.trackBy(row)) + '+' + column.id,attrs:{"original":row.original_[column.id],"in-table":"","font":"14px Roboto"},on:{"input":function($event){_vm.selectIfNot(row), _vm.$emit('input-editable', $event, row, column.id, false)}},model:{value:(row[column.id]),callback:function ($$v) {_vm.$set(row, column.id, $$v)},expression:"row[column.id]"}},'Field',_vm.filterColumnAttrs(column, row),false)):_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
            (column.rowTooltip ? column.rowTooltip(row) : '') +
            (_vm.errors[column.id] && _vm.errors[column.id].check(row)
              ? _vm.errors[column.id].message(row)
              : '')
          ),expression:"\n            (column.rowTooltip ? column.rowTooltip(row) : '') +\n            (errors[column.id] && errors[column.id].check(row)\n              ? errors[column.id].message(row)\n              : '')\n          "}],class:[
            column.class && column.class(row),
            _vm.errors[column.id] && _vm.errors[column.id].check(row) ? _vm.errors[column.id].class : '' ]},[_vm._v(" "+_vm._s(column.get ? column.get(row) : column.filter ? column.filter(_vm.getPath(row, column.id), row) : _vm.getPath(row, column.id))+" ")])],2):_vm._e()]})]}},{key:"child",fn:function(row){return _vm._l((_vm.childrenColumns),function(column){return _c('td',{key:column.id,class:[column.align, column.class && column.class(row)],attrs:{"colspan":column.span}},[(_vm.$scopedSlots[("child-column-" + (column.id))])?_vm._t(("child-column-" + (column.id)),null,{"row":row},row):(typeof column.editable === 'function' ? column.editable(row) : column.editable)?_c('Field',_vm._b({key:row.$key || row[_vm.childrenTrackBy] + '+' + column.id,attrs:{"original":row.original_[column.id],"in-table":"","font":"14px Roboto"},on:{"input":function($event){_vm.selectIfNot(row), _vm.$emit('input-editable', $event, row, column.id, true)}},model:{value:(row[column.id]),callback:function ($$v) {_vm.$set(row, column.id, $$v)},expression:"row[column.id]"}},'Field',_vm.filterColumnAttrs(column, row),false)):_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(column.rowTooltip ? column.rowTooltip(row) : ''),expression:"column.rowTooltip ? column.rowTooltip(row) : ''"}]},[_vm._v(" "+_vm._s(column.get ? column.get(row) : column.filter ? column.filter(_vm.getPath(row, column.id), row) : _vm.getPath(row, column.id))+" ")])],2)})}},(_vm.hasTotal)?{key:"total",fn:function(){return _vm._l((_vm.columns),function(column){return _c('td',{key:column.id,class:column.align,attrs:{"colspan":column.span}},[_vm._v(" "+_vm._s(column.total && (_vm.selected && _vm.selected.length ? column.total(_vm.selected) : column.total(_vm.dataset)))+" ")])})},proxy:true}:null,(_vm.$scopedSlots.expand)?{key:"expand",fn:function(ref){
          var row = ref.row;
return [_vm._t("expand",null,{"row":row})]}}:null,(_vm.$scopedSlots.counter)?{key:"counter",fn:function(row){return [_vm._t("counter",null,null,row)]}}:null],null,true)},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }