<template>
  <Picker
    :value="isoDate"
    @input="(e) => setDate(e)"
    v-bind="$attrs"
    v-on="listenersWithoutInput"
    type="d"
  />
</template>

<script lang="ts">
import { defineComponent, computed } from "@vue/composition-api";
import { LocalDate } from "@js-joda/core";

export default defineComponent({
  props: {
    value: null,
  },

  setup(props, { listeners, emit }) {
    return {
      isoDate: computed(() => {
        return props.value !== null ? props.value.toString() : null;
      }),
      setDate(e: string | null) {
        emit("input", e !== null ? LocalDate.parse(e) : null);
      },
      listenersWithoutInput: computed(() => {
        return {
          ...listeners,
          input: undefined,
        };
      }),
    };
  },
});
</script>
