import { composeRoutes, composeTabs } from "@/services/routerUtils";

const title = "Cheques Recebidos";
const icon = "checkbook";
const permissionTag = "caixa";
const base = "chequesRecebidos";

export const agrupar = {
  name: `${base}Agrupar`,
  tabTitle: "Agrupar",
  path: "agrupar",
  title,
  component: () => import("./agrupar.vue"),
};

export const agruparRelatorio = {
  name: `${base}AgruparRelatorio`,
  path: "agrupar/relatorio",
  title: "Relatório de Cheques a Agrupar",
  component: () => import("./agrupar-relatorio.vue"),
};

export const depositar = {
  name: `${base}Depositar`,
  tabTitle: "Depositar",
  path: "depositar",
  title,
  component: () => import("./depositar.vue"),
};

export const depositarRelatorio = {
  name: `${base}DepositarRelatorio`,
  path: "depositar/relatorio",
  title: "Relatório de Controle de Depósito",
  component: () => import("./depositar-relatorio.vue"),
};

export const atualizar = {
  name: `${base}Atualizar`,
  tabTitle: "Atualizar",
  path: "atualizar",
  title,
  component: () => import("./atualizar.vue"),
};

export const cobrar = {
  name: `${base}Cobrar`,
  tabTitle: "Cobrar",
  path: "cobrar",
  title,
  component: () => import("./cobrar.vue"),
};

export const imagens = {
  name: `${base}Imagens`,
  tabTitle: "Imagens",
  path: "imagens",
  title,
  component: () => import("./imagens.vue"),
};

export const finalizados = {
  name: `${base}Finalizados`,
  tabTitle: "Finalizados",
  path: "finalizados",
  title,
  component: () => import("./finalizados.vue"),
};

const sequence = [agrupar, depositar, atualizar, cobrar, imagens, finalizados];

export const tabs = composeTabs(sequence);
export const navbarLinks = [{ title, icon, permissionTag, name: sequence[0].name }];
export const routes = composeRoutes(
  [...sequence, agruparRelatorio, depositarRelatorio],
  { permissionTag },
  "/cheques-recebidos/",
);
