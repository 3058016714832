import { composeRoutes, composeTabs } from "@/services/routerUtils";

const title = "Escrituração";
const icon = "file-percent";
const permissionTag = "escrituracao";
const base = "escrituracao";

export const importar = {
  name: `${base}Importar`,
  path: "importar",
  title,
  tabTitle: "Importar\n Documentos",
  component: () => import("./importar.vue"),
};

export const gerar = {
  name: `${base}Gerar`,
  path: "gerar",
  title,
  tabTitle: "Gerar SPED",
  component: () => import("./gerar.vue"),
};

const sequence = [importar, gerar];

export const tabs = composeTabs(sequence);
export const navbarLinks = [{ title, icon, permissionTag, name: sequence[0].name }];
export const routes = composeRoutes(sequence, { permissionTag }, "/escrituracao/");
