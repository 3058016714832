import { composeRoutes } from "@/services/routerUtils";

const title = "Painel";
const icon = "dashboard";
const permissionTag = "painel";
const base = "painel";

export const painel = {
  name: base,
  title,
  path: "",
  component: () => import("./main.vue"),
};

export const navbarLinks = [{ title, icon, permissionTag, name: painel.name }];
export const routes = composeRoutes([painel], { permissionTag }, "/painel/");
