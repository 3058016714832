<template>
  <div v-if="auth" class="navbar">
    <div class="backdrop" v-if="forceMenu" @click="$emit('update:force-menu', false)" />
    <nav :class="{ force: forceMenu }">
      <TextField
        class="search-menu outlined"
        v-model="search"
        :label="`Pesquisar Menu...`"
        hide-helper
      />
      <div class="links" ref="linksDiv">
        <template v-for="{ name, title, icon, sublinks, group } in links">
          <RouterLink replace class="menu-item waves-effect" :to="{ name }" :key="name" v-waves>
            <Icon :name="icon" />
            <div class="title">{{ title.value ? title.value : title }}</div>
            <IconButton
              class="expand-button"
              v-if="sublinks"
              :class="expandedGroup === group && 'inverted'"
              @click.stop="expandedGroup = expandedGroup !== group ? group : null"
            >
              <Icon name="expand" />
            </IconButton>
          </RouterLink>
          <template v-for="{ partOf, name, icon, title } in sublinks">
            <RouterLink
              replace
              class="menu-item waves-effect"
              :to="{ name }"
              :key="name"
              v-waves
              v-if="expandedGroup === partOf"
            >
              <Icon v-if="icon" :key="name + '/icon'" :name="icon" />
              <div :key="name" class="sub-title">{{ title }}</div>
            </RouterLink>
          </template>
        </template>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted, Ref } from "@vue/composition-api";
import { useOnScroll } from "vue-composable";

import auth from "@/services/auth";
import { navbarLinks } from "@/pages/config";
import usePersistent from "@/services/usePersistent";

function findIcons(routes: Array<any>) {
  const groups: { [key: string]: any } = {};

  return routes
    .map((route) => {
      const control: any = {
        icon: route.icon,
        title: route.title,
        name: route.name,
        permissionTag: route.permissionTag,
      };

      if (route.group) {
        if (!groups[route.group]) {
          groups[route.group] = [];
        }
        control.sublinks = groups[route.group];
        control.name = control.name || route.children[0].name;
        control.group = route.group;
      }

      if (route.partOf) {
        if (!groups[route.partOf]) {
          groups[route.partOf] = [];
        }
        control.partOf = route.partOf;
        groups[route.partOf].push(control);
        return null;
      }

      return control;
    })
    .filter((route) => route);
}

export default defineComponent({
  name: "TheNavbar",

  props: {
    forceMenu: Boolean,
  },

  setup() {
    const allLinks = findIcons(navbarLinks);
    const links = computed(() => {
      const authValue = auth.value;
      if (authValue)
        return allLinks.filter(({ permissionTag }) => authValue.permissoes.has(permissionTag));
      return [];
    });
    const linksDiv = ref(null) as Ref<HTMLElement | null>;
    const currentScrollTop = useOnScroll(linksDiv).scrollTop;
    const scrollTop = usePersistent({ name: "TheNavbar" }, "scrollTop", currentScrollTop.value);

    const search = usePersistent({ name: "TheNavbar" }, "search", "");
    const searchedLinks = computed(() => {
      return links.value.filter((x) => {
        const unwrap = typeof x.title !== "string" ? x.title.value : x.title;
        return unwrap.toLocaleLowerCase().includes(search.value.toLocaleLowerCase());
      });
    });

    onMounted(() => {
      if (linksDiv.value) {
        linksDiv.value.scrollTop = scrollTop.value;
      }
      watch(
        () => currentScrollTop.value,
        () => {
          scrollTop.value = currentScrollTop.value;
        },
      );
    });

    return {
      expandedGroup: ref(null),
      currentScrollTop,
      links: searchedLinks,
      auth,
      linksDiv,
      search,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "~ligno/src/shadows";
@import "@/styles/colors";

nav {
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 300;

  max-width: 232px;
  min-width: 232px;
  box-shadow: $whiteframe-shadow-16dp;

  transition: all 0.2s;
}

nav > .links {
  flex-direction: column;
  padding: 8px 0;
  overflow: auto;
  flex: 1;
}

a.menu-item {
  display: flex;
  flex-flow: row;
  justify-content: left;
  align-items: center;
  height: 40px;
  text-decoration: none;
  color: #000;
}

.menu-item > div {
  display: flex;
}

.links {
  .menu-item svg {
    fill: rgba(117, 117, 117, 1);
    flex: none;
  }
  .menu-item > svg {
    margin: 0 16px;
  }
}

a.menu-item:focus {
  outline: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

a.menu-item::-moz-focus-inner {
  border: 0;
}

@media print {
  nav {
    display: none;
  }
}

@media only screen and (max-width: 960px) {
  .backdrop {
    z-index: 300;
    position: absolute;
    width: 100%;
    height: calc(100vh - 56px);
    background: rgba(33, 33, 33, 0.5);
    transition: all 0.2s;
  }
  nav {
    position: absolute;
    height: calc(100vh - 56px);
    left: -236px;
  }
  nav.force {
    left: 0;
    z-index: 999;
    background: white;
  }
}

.navbar {
  display: flex;
  background: get-primary-color();
}

.row {
  display: flex;
}
.column {
  flex: 1;
}
svg {
  fill: #fff;
}

.title {
  flex: 1;
}
.sub-title {
  margin-left: 56px;
  flex: 1;
}
.links {
  background: white;
}
.links svg {
  fill: #000;
}

.inverted {
  transform: rotate(180deg);
}

.expand-button {
  transition: transform 0.2s;
}

img {
  margin: 16px 16px 4px;
}

.menu-item.router-link-active {
  background: get-secondary-color();
  color: white;

  svg {
    fill: #fff;
  }
}

.search-menu {
  margin-top: 0;
}
</style>
