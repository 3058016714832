import { composeRoutes } from "@/services/routerUtils";

const title = "Rastreamento";
const icon = "my_location";
const permissionTag = "rastreamento";
const base = "rastreamento";

export const rastreamento = {
  name: base,
  title,
  path: "",
  component: () => import("./main.vue"),
};

export const navbarLinks = [{ title, icon, permissionTag, name: rastreamento.name }];
export const routes = composeRoutes([rastreamento], { permissionTag }, "/rastreamento/");
