import { composeRoutes } from "@/services/routerUtils";

const title = "Cartões Recebidos";
const icon = "credit-card";
const permissionTag = "caixa";
const base = "cartoes";

export const relacao = {
  name: `${base}Relacao`,
  title,
  path: "",
  component: () => import("./relacao.vue"),
};

export const navbarLinks = [{ title, icon, permissionTag, name: relacao.name }];
export const routes = composeRoutes([relacao], { permissionTag }, "/cartoes/");
