import { composeRoutes } from "@/services/routerUtils";

const title = "Títulos a Receber";
const icon = "titulo-receber";
const permissionTag = "titulos-receber";
const base = "titulosReceber";

export const relacao = {
  name: `${base}Relacao`,
  path: "relacao",
  title,
  component: () => import("./relacao.vue"),
};

export const novo = {
  name: `${base}Novo`,
  path: "novo",
  title: "Novo Título a Receber",
  component: () => import("./novo.vue"),
};

export const detalhe = {
  name: `${base}Detalhe`,
  path: ":id",
  title: "Título a Receber",
  component: () => import("./detalhe.vue"),
};

export const relacaoRelatorio = {
  name: `${base}RelacaoRelatorio`,
  path: "relacao/relatorio",
  title: "Relatório de Títulos a Receber",
  component: () => import("./relacao-relatorio.vue"),
};

export const navbarLinks = [{ title, icon, permissionTag, name: relacao.name }];
export const routes = composeRoutes(
  [relacao, novo, detalhe, relacaoRelatorio],
  { permissionTag },
  "/titulos-receber/",
);
