import { computed } from "@vue/composition-api";
import { composeRoutes, composeTabs } from "@/services/routerUtils";

import { beautifulDate, time } from "@/filters/date";

import caixaState from "@/services/caixa";

const title = computed(() => {
  return caixaState.value != null
    ? `Caixa ${caixaState.value.id} - Aberto ${beautifulDate(
        caixaState.value.momentoAbertura,
      )} às ${time(caixaState.value.momentoAbertura)}`
    : "Caixa";
});

const navbarTitle = computed(() => {
  if (caixaState.value === undefined) {
    return "Caixa ???";
  }

  if (caixaState.value === null) {
    return "Caixa";
  }

  return `Caixa ${caixaState.value.id}`;
});

const icon = "receipt";
export const permissionTag = "caixa";
const base = "caixas";

export const atual = {
  name: `${base}Atual`,
  tabTitle: "Atual",
  path: "atual",
  title,
  component: () => import("./atual.vue"),
};

export const relacao = {
  name: `${base}Relacao`,
  tabTitle: "Relação",
  path: "relacao",
  title,
  component: () => import("./relacao.vue"),
};

export const liquidar = {
  name: `${base}Liquidar`,
  tabTitle: "Liquidar",
  path: "liquidar",
  title,
  component: () => import("./liquidar.vue"),
  tabDisabled: computed(() => caixaState.value == null),
};

export const detalhe = {
  name: `${base}Detalhe`,
  path: ":id",
  title: "Caixa",
  component: () => import("./detalhe.vue"),
};

const sequence = [relacao, atual, liquidar];

export const tabs = composeTabs(sequence);
export const navbarLinks = [
  {
    title: navbarTitle,
    icon,
    permissionTag,
    name: sequence[1].name,
  },
];
export const routes = composeRoutes([...sequence, detalhe], { permissionTag }, "/caixas/");
