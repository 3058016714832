import { composeRoutes } from "@/services/routerUtils";

const title = "Provisionamento";
const icon = "calendar-clock";
const permissionTag = "conciliacao-contabil";
const base = "provisionamento";

export const relacao = {
  name: `${base}Relacao`,
  path: "relacao",
  title,
  component: () => import("./relacao.vue"),
};

export const navbarLinks = [{ title, icon, permissionTag, name: relacao.name }];
export const routes = composeRoutes([relacao], { permissionTag }, "/provisionamento/");
