<template>
  <header v-if="auth" :class="{ tabbed }">
    <div class="pre-title">
      <IconButton @click="$emit('toggle-menu')">
        <Icon name="menu" />
      </IconButton>
      <div class="logo">
        f8 <span class="version">{{ version }}</span>
      </div>
    </div>
    <slot />
    <IconButton @click="reload">
      <Icon name="refresh" />
    </IconButton>
    <IconButton @click="$router.replace({ name: 'configuracao' })">
      <Icon name="settings" />
    </IconButton>
    <Popover
      @blur="orgaoOpen = false"
      ref="orgaoPopover"
      tabindex="0"
      placement="bottom-end"
      v-model="orgaoOpen"
      @mousedown.prevent
    >
      <IconButton @click="orgaoClickOpen" class="icon-button">
        <div v-if="auth.idOrgao === 0" class="icon-wildcard" />
        <img
          v-if="auth.idOrgao === 1"
          :src="TaxiFaixaVermelhaIcon"
          :style="{ 'min-width': '36px', width: '36px', height: '36px' }"
        />
        <img
          v-if="auth.idOrgao === 5"
          :src="Taxi333Icon"
          :style="{ 'min-width': '36px', width: '36px', height: '36px' }"
        />
        <img
          v-if="auth.idOrgao === 6"
          :src="TaxiCapitalIcon"
          :style="{ 'min-width': '36px', width: '36px', height: '36px' }"
        />
        <img
          v-if="auth.idOrgao === 4"
          :src="TaxiFaixaVermelhaAgregadosIcon"
          :style="{ 'min-width': '36px', width: '36px', height: '36px' }"
        />
      </IconButton>
      <template #popper>
        <div class="popper">
          <div class="list">
            <div
              class="list-item"
              v-waves
              v-if="auth.orgaos.indexOf(0) !== -1"
              @click="setIdOrgao(0)"
            >
              <IconButton class="icon-wildcard" />
              <div class="list-item-text">Todos os órgãos</div>
            </div>
            <div
              class="list-item"
              v-waves
              v-if="auth.orgaos.indexOf(1) !== -1"
              @click="setIdOrgao(1)"
            >
              <IconButton>
                <img
                  :src="TaxiFaixaVermelhaIcon"
                  :style="{ 'min-width': '36px', width: '36px', height: '36px' }"
                />
              </IconButton>
              <div class="list-item-text">Faixa Vermelha</div>
            </div>
            <div
              class="list-item"
              v-waves
              v-if="auth.orgaos.indexOf(5) !== -1"
              @click="setIdOrgao(5)"
            >
              <IconButton>
                <img
                  :src="Taxi333Icon"
                  :style="{ 'min-width': '36px', width: '36px', height: '36px' }"
                />
              </IconButton>
              <div class="list-item-text">Cidade</div>
            </div>
            <div
              class="list-item"
              v-waves
              v-if="auth.orgaos.indexOf(6) !== -1"
              @click="setIdOrgao(6)"
            >
              <IconButton>
                <img
                  :src="TaxiCapitalIcon"
                  :style="{ 'min-width': '36px', width: '36px', height: '36px' }"
                />
              </IconButton>
              <div class="list-item-text">Capital</div>
            </div>
            <div
              class="list-item"
              v-waves
              v-if="auth.orgaos.indexOf(4) !== -1"
              @click="setIdOrgao(4)"
            >
              <IconButton>
                <img
                  :src="TaxiFaixaVermelhaAgregadosIcon"
                  :style="{ 'min-width': '36px', width: '36px', height: '36px' }"
                />
              </IconButton>
              <div class="list-item-text">Agregados Faixa Vermelha</div>
            </div>
          </div>
        </div>
      </template>
    </Popover>
    <Popover
      @blur="userOpen = false"
      ref="userPopover"
      placement="bottom-end"
      v-model="userOpen"
      tabindex="0"
      @mousedown.prevent
    >
      <IconButton class="avatar-button" @click="userClickOpen">
        <Avatar :size="36" :usuario="auth.usuario" />
      </IconButton>
      <template #popper>
        <div class="popper">
          <div class="centered">
            <Avatar class="avatar" :usuario="auth.usuario" />
            <div class="usuario">{{ auth.nome }}</div>
            <div class="setor">{{ auth.setor }}</div>
          </div>
          <div class="buttons">
            <TextButton color="secondary" raised @click="$router.replace(perfilRoute)">
              Alterar Senha
            </TextButton>
            <TextButton color="negative" raised @click="logout"> Sair da Conta </TextButton>
          </div>
        </div>
      </template>
    </Popover>
  </header>
</template>

<script lang="ts">
import { nextTick, defineComponent, ref } from "@vue/composition-api";
import auth, { logout, setIdOrgao } from "@/services/auth";
import useRouter from "@/services/useRouter";
import { perfil as perfilRoute, login as loginRoute } from "@/pages/config";
import TaxiFaixaVermelhaIcon from "@/assets/taxi-faixa-icone.svg";
import Taxi333Icon from "@/assets/taxi-333-icone.svg";
import TaxiCapitalIcon from "@/assets/capital-marca.svg";
import TaxiFaixaVermelhaAgregadosIcon from "@/assets/taxi-faixa-agregados-icone.svg";

export default defineComponent({
  props: {
    tabbed: Boolean,
  },
  setup(props, { emit }) {
    const { router, route } = useRouter();

    const orgaoOpen = ref(false);
    const orgaoPopover = ref(null as { $el: HTMLElement } | null);
    const userOpen = ref(false);
    const userPopover = ref(null as { $el: HTMLElement } | null);

    return {
      auth,
      userOpen,
      orgaoOpen,
      perfilRoute,
      version: process.env.VUE_APP_F8_VERSION,
      reload() {
        emit("reload");
      },
      async orgaoClickOpen() {
        orgaoOpen.value = !orgaoOpen.value;
        if (orgaoOpen.value) {
          await nextTick();
          if (orgaoPopover.value) {
            orgaoPopover.value.$el.focus();
          }
        }
      },
      async userClickOpen() {
        userOpen.value = !userOpen.value;
        if (userOpen.value) {
          await nextTick();
          if (userPopover.value) {
            userPopover.value.$el.focus();
          }
        }
      },
      setIdOrgao(idOrgao: number) {
        setIdOrgao(idOrgao);
        emit("reload");
      },
      orgaoPopover,
      userPopover,
      logout() {
        logout();
        router.replace({
          name: loginRoute.name,
          query: { redirectTo: (route as unknown as { fullPath: string }).fullPath },
        });
      },
      TaxiFaixaVermelhaIcon,
      Taxi333Icon,
      TaxiCapitalIcon,
      TaxiFaixaVermelhaAgregadosIcon
    };
  },
});
</script>

<style lang="scss" scoped>
@import "~ligno/src/shadows";
@import "~ligno/src/colors";

header {
  background-color: get-primary-color();
  min-height: 56px;
  max-height: 56px;

  color: #fff;
  display: flex;
  align-items: center;
  z-index: 800;
  padding: 8px;
}

h1 {
  flex: 1;
  padding: 16px;
  font-size: 20px;
  font-weight: 400;
}

.loader circle {
  stroke: $blue-500;
}

.warning,
.loader {
  position: absolute;
  right: 4px;
  top: 4px;
}

.warning {
  fill: #ff9800;
}

svg {
  fill: #fff;
}

@media print {
  header {
    display: none !important;
  }
}

.logo {
  font-size: 28px;
  font-weight: 300;
  padding: 8px;
}

.version {
  font-size: 16px;
}

.avatar-button {
  margin: 4px;
}

.icon-button {
  margin: 8px;
  z-index: 1;

  img {
    z-index: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 75%;
    width: 75%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #fff;
  }
}

.popper {
  padding: 8px;
  color: #000;
  width: 220px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.centered {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 8px;
  padding-top: 0;
}

.buttons {
  display: flex;
  flex-flow: column;
  padding: 8px;
  padding-bottom: 4px;
  > * {
    margin: 4px;
  }
}

.avatar {
  margin: 8px;
}

.usuario {
  font-weight: 500;
}

.setor {
  font-weight: 300;
}

.pre-title {
  display: flex;
  width: 224px;
  align-items: center;
}

.list {
  display: flex;
  flex-flow: column;
  flex: 1;
  align-self: stretch;
}

.list-item {
  display: flex;
  flex-flow: row;
  flex: 1;
}

.list-item-text {
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: 8px;
}

.icon-wildcard::after {
  position: fixed;
  content: "*";
  font-size: 64px;
  top: 11px;
  left: 3px;
  color: black;
}
</style>
