var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProcessScreen',_vm._g(_vm._b({ref:"processScreen",attrs:{"fields":_vm.fields,"filter":_vm.filter},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(slot,key){return {key:key,fn:function(slotProps){return [_vm._t(key,null,null,slotProps)]}}}),{key:"search",fn:function(){return [_c('div',{staticClass:"filters"},[_vm._l((_vm.fields.filter(
          function (ref) {
                  var id = ref.id;
                  var type = ref.type;

                  return _vm.filter[id] !== undefined && id !== 'q' && type !== 'password';
}
        )),function(ref){
        var id = ref.id;
        var label = ref.label;
        var options = ref.options;
        var control = ref.control;
        var type = ref.type;
return [_c('div',{key:id,staticClass:"chip"},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(label)+" ")]),(options)?_c('Dropdown',{attrs:{"value":_vm.filter[id],"inline":"","options":options},on:{"input":function (v) {
                var _obj;

                return _vm.updateFilter(( _obj = {}, _obj[id] = v, _obj ));
        }}}):(
              control === 'picker' ||
              type === Date ||
              (type instanceof Array && type.every(function (d) { return d === Date; }))
            )?_c('Picker',{attrs:{"value":_vm.filter[id],"type":type instanceof Array ? '*d' : typeof type === 'function' ? 'd' : type,"inline":""},on:{"input":function (v) {
                    var _obj;

                    return _vm.updateFilter(( _obj = {}, _obj[id] = v, _obj ));
        }}}):_c('TextField',{attrs:{"value":_vm.filter[id],"inline":""},on:{"input":function (v) {
                    var _obj;

                    return _vm.updateFilter(( _obj = {}, _obj[id] = v, _obj ));
        }}}),_c('IconButton',{staticClass:"remove-button",on:{"click":function($event){return _vm.$set(_vm.filter, id, undefined)}}},[_c('Icon',{attrs:{"name":"clear"}})],1)],1),_vm._v(" e ")]}),_c('div',{staticClass:"chip"},[_c('Dropdown',{attrs:{"inline":"","options":_vm.fields.filter(function (ref) {
                    var id = ref.id;

                    return id != 'q';
}).map(function (ref) {
                    var id = ref.id;
                    var label = ref.label;

                    return ({ id: id, text: label });
})},on:{"input":function (id) {
              _vm.$set(_vm.filter, id, null);
            }}})],1),_c('div',{staticClass:"spacer"}),_c('Popover',{ref:"columnsPopover",attrs:{"tabindex":"0"},on:{"blur":function($event){_vm.columnsOpened = false}},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('div',{staticClass:"popper"},[_vm._l((_vm.allColumns),function(column,id){return [_c('div',{key:id,staticClass:"option"},[_c('Checkbox',{attrs:{"value":+!!_vm.selectedColumns.find(function (x) { return x === id; })},on:{"input":function (value) { return _vm.onChange(value, id); }}},[_vm._v(_vm._s(column.name))])],1)]})],2)]},proxy:true}]),model:{value:(_vm.columnsOpened),callback:function ($$v) {_vm.columnsOpened=$$v},expression:"columnsOpened"}},[_c('TextButton',{staticClass:"customize-columns",on:{"click":_vm.columnsClickOpen}},[_vm._v(" Colunas "),_c('div',{staticClass:"lgn-triangle"})])],1)],2)]},proxy:true}],null,true)},'ProcessScreen',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }