import { composeRoutes } from "@/services/routerUtils";

const title = "Usuários";
const icon = "person";
const permissionTag = "usuarios";
const base = "usuarios";

export const relacao = {
  name: `${base}Relacao`,
  title,
  path: "",
  component: () => import("./relacao.vue"),
};

export const navbarLinks = [{ title, icon, permissionTag, name: relacao.name }];
export const routes = composeRoutes([relacao], { permissionTag }, "/usuarios/");
