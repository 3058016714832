import { composeRoutes, composeTabs } from "@/services/routerUtils";
import { routes as rateioRoutes } from "./rateio/config";

const title = "Rateios";
const icon = "chart_pie";
const permissionTag = "rateios";
const base = "rateios";

export const relacao = {
  name: `${base}Relacao`,
  tabTitle: "Relação",
  path: "relacao",
  title,
  component: () => import("./relacao.vue"),
};

export const emitir = {
  name: `${base}Emitir`,
  tabTitle: "Relação & Emitir\n Boletos",
  path: "emitir",
  title,
  component: () => import("./emitir.vue"),
};

export const emitirRelatorio = {
  name: `${base}EmitirRelatorio`,
  path: "emitir/relatorio",
  title: "Relatório de Rateio",
  component: () => import("./emitir-relatorio.vue"),
};

export const imprimir = {
  name: `${base}Imprimir`,
  tabTitle: "Imprimir\n Boletos",
  path: "imprimir",
  title,
  component: () => import("./imprimir.vue"),
};

export const creditos = {
  name: `${base}Creditos`,
  tabTitle: "Créditos",
  path: "creditos",
  title,
  component: () => import("./creditos.vue"),
};

export const imprimirRelatorio = {
  name: `${base}ImprimirRelatorio`,
  title: "Relatório de Boletos do Rateio",
  path: "imprimir/relatorio",
  component: () => import("./imprimir-relatorio.vue"),
};

const sequence = [relacao, emitir, imprimir, creditos];

export const tabs = composeTabs(sequence);
export const navbarLinks = [{ title, icon, permissionTag, name: sequence[0].name }];
export const routes = composeRoutes(
  [...sequence, emitirRelatorio, imprimirRelatorio, ...rateioRoutes],
  { permissionTag },
  "/rateios/",
);
