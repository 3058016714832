import { render, staticRenderFns } from "./RequestAutocomplete.vue?vue&type=template&id=923309f8"
import script from "./RequestAutocomplete.vue?vue&type=script&lang=ts"
export * from "./RequestAutocomplete.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.27_css-loader@6.11.0_webpack@5.91.0__ejs@3.1.10_loda_tcriz5qadyx5urhthcrpjy5sai/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports