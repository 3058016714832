import Vue from "vue";

import AppLayout from "./AppLayout.vue";
import ProcessScreen from "./ProcessScreen.vue";
import NewProcessScreen from "./NewProcessScreen.vue";
import LoadingPulse from "./LoadingPulse.vue";
import Icon from "./Icon.vue";
import Avatar from "./Avatar.vue";
import MiniAvatar from "./MiniAvatar.vue";
import Map from "./Map.vue";
import Card from "./Card.vue";
import RequestAutocomplete from "./RequestAutocomplete.vue";
import TabGroup from "./TabGroup.vue";
import Relatorio from "./Relatorio.vue";
import LocalDatePicker from "./LocalDatePicker.vue";

Object.entries({
  ProcessScreen,
  NewProcessScreen,
  Icon,
  Avatar,
  MiniAvatar,
  LoadingPulse,
  Map,
  Card,
  RequestAutocomplete,
  AppLayout,
  TabGroup,
  Relatorio,
  LocalDatePicker,
}).forEach(([name, component]) => {
  Vue.component(name, component);
});
