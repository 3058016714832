import { composeRoutes } from "@/services/routerUtils";

const title = "Trajetos";
const icon = "timeline";
const permissionTag = "trajetos";
const base = "trajetos";

export const trajetos = {
  name: base,
  title,
  path: "",
  component: () => import("./main.vue"),
  redirectF8: "/trajetos",
};

export const navbarLinks = [{ title, icon, permissionTag, name: trajetos.name }];
export const routes = composeRoutes([trajetos], { permissionTag }, "/trajetos/");
