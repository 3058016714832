<template functional>
  <Dropdown
    v-if="props.options"
    v-bind="data.attrs"
    v-on="listeners"
    :label="props.label"
    :options="props.options"
  />
  <Checkbox
    v-else-if="props.type === Boolean || props.type === 'boolean'"
    v-bind="data.attrs"
    v-on="listeners"
  >
    {{ props.label }}
  </Checkbox>
  <Picker
    v-else-if="
      props.control === 'picker' ||
      props.type === Date ||
      (props.type instanceof Array && props.type.every((d) => d === Date))
    "
    v-bind="data.attrs"
    v-on="listeners"
    :label="props.label"
    :type="props.type instanceof Array ? '*d' : typeof props.type === 'function' ? 'd' : props.type"
  />
  <CurrencyTextField
    v-else-if="props.type === 'money'"
    :label="props.label"
    v-bind="data.attrs"
    v-on="listeners"
  />
  <TextField
    v-bind="data.attrs"
    v-on="listeners"
    v-else
    v-formatter="props.formatter"
    :type="props.type"
    :label="props.label"
  />
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  inheritAttrs: false,
  props: {
    type: null,
    label: String,
    options: null,
    control: String,
    formatter: null,
  },
});
</script>
