import { composeRoutes } from "@/services/routerUtils";

const title = "Ocorrências";
const icon = "list";
const permissionTag = "ocorrencias:criar";
const base = "ocorrencias";

export const relacao = {
  name: `${base}Relacao`,
  title,
  path: "",
  component: () => import("./relacao.vue"),
};

export const detalhe = {
  name: `${base}Detalhe`,
  title,
  path: ":id",
  component: () => import("./detalhe.vue"),
};

export const novo = {
  name: `${base}Novo`,
  title: "Nova Ocorrência",
  path: "novo",
  component: () => import("./novo.vue"),
};

export const ficha = {
  name: `${base}Ficha`,
  title: "Ficha de Ocorrência",
  path: ":id/ficha",
  component: () => import("./ficha.vue"),
};

export const navbarLinks = [{ title, icon, permissionTag, name: relacao.name }];
export const routes = composeRoutes(
  [relacao, novo, detalhe, ficha],
  { permissionTag },
  "/ocorrencias/",
);
