import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "@/pages/config";
import { stringifyQuery } from "@/services/utils";
import { queueMessage } from "@/services/message-queue";

const currentLocation = `${window.location.pathname}${window.location.search}`;
window.history.replaceState({ sentinel: true }, "f8", "/");
window.history.pushState(null, "f8", currentLocation);

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
  stringifyQuery,
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    queueMessage({
      message: "O seu aplicativo ficou dessincronizado, atualize-o recarregando a página",
      class: "error",
    });
  }
});

export default router;
