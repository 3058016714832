import { composeRoutes } from "@/services/routerUtils";

const icon = "cash";
const permissionTag = "virtuais";
const base = "virtuais";

export const relacao = {
  name: `${base}Relacao`,
  path: "relacao",
  title: "Virtuais",
  component: () => import("./relacao.vue"),
};

export const relacaoParcelas = {
  name: `${base}RelacaoParcelas`,
  path: "parcelas/relacao",
  title: "Parcelas dos Virtuais",
  component: () => import("./relacao-parcelas.vue"),
};

export const detalhe = {
  name: `${base}Detalhe`,
  path: ":id",
  title: "Virtual",
  component: () => import("./detalhe.vue"),
};

export const navbarLinks = [
  { title: relacao.title, icon, permissionTag, name: relacao.name },
  { title: relacaoParcelas.title, icon, permissionTag, name: relacaoParcelas.name },
];
export const routes = composeRoutes(
  [relacao, relacaoParcelas, detalhe],
  { permissionTag },
  "/virtuais/",
);
