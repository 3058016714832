<template>
  <div class="tabgroup">
    <div class="tabwrapper">
      <div class="tabdisplay">
        <RouterLink
          replace
          class="tabname"
          :to="{ name: tab.name }"
          v-for="tab in tabs"
          :key="tab.name"
          :class="{
            active: $route.name === tab.name,
            disabled: tab.disabled && tab.disabled.value,
          }"
        >
          <div>{{ tab.title.split("\n")[0] }}</div>
          <div>{{ tab.title.split("\n")[1] }}</div>
        </RouterLink>
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "tab-group",
  props: {
    tabs: Array,
  },
});
</script>

<style lang="scss" src="./TabGroup.scss"></style>
