import { composeRoutes, composeTabs } from "@/services/routerUtils";

const title = "Faturas TaxiDigital";
const icon = "taxi-digital";
const permissionTag = "faturas-taxi-digital";
const base = "faturasTaxiDigital";

export const registro = {
  name: `${base}Registro`,
  tabTitle: "Registro",
  path: "registro",
  title,
  component: () => import("./registro.vue"),
};

export const pagamentoCancelamento = {
  name: `${base}PagamentoCancelamento`,
  tabTitle: "Pagamento\n & Cancelamento",
  path: "pagamento-cancelamento",
  title,
  component: () => import("./pagamento-cancelamento.vue"),
};

const sequence = [registro, pagamentoCancelamento];

export const tabs = composeTabs(sequence);
export const navbarLinks = [{ title, icon, permissionTag, name: sequence[0].name }];
export const routes = composeRoutes(sequence, { permissionTag }, "/faturas-taxi-digital/");
