import { filters } from "common";
import { dateISO, datetime, date, time, beautifulDate, customDate } from "./date";
import { percen, sum } from "./numbers";
import { selectionCount, s } from "./plurals";

const { titulo, money, moneyZero } = filters;

const capitalize = (sentence: string) =>
  sentence
    .split(" ")
    .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
    .join(" ");

export {
  titulo,
  datetime,
  date,
  money,
  percen,
  sum,
  selectionCount,
  capitalize,
  time,
  s,
  customDate,
  moneyZero,
  dateISO,
  beautifulDate,
};
