import { composeRoutes, composeTabs } from "@/services/routerUtils";

const title = "Vouchers";
const icon = "ticket";
const permissionTag = "vouchers";
const base = "vouchers";

export const relacao = {
  name: `${base}Relacao`,
  tabTitle: "Relação",
  path: "relacao",
  title,
  component: () => import("./relacao.vue"),
};

export const importar = {
  name: `${base}Importar`,
  tabTitle: "Importar",
  path: "importar",
  title,
  component: () => import("./importar.vue"),
};

export const trocas = {
  name: `${base}Trocas`,
  tabTitle: "Trocas",
  path: "trocas",
  title,
  component: () => import("./trocas.vue"),
};

export const validacoes = {
  name: `${base}Validacoes`,
  tabTitle: "Validações",
  path: "validacoes",
  title,
  component: () => import("./validacoes.vue"),
};

export const trocasRelatorio = {
  name: `${base}TrocasRelatorio`,
  title: "Relatório de Trocas de Voucher",
  path: "trocas/relatorio",
  component: () => import("./trocas-relatorio.vue"),
};

const sequence = [relacao, importar, trocas, validacoes];

export const tabs = composeTabs(sequence);
export const navbarLinks = [{ title, icon, permissionTag, name: sequence[0].name }];
export const routes = composeRoutes(
  [...sequence, trocasRelatorio],
  { permissionTag },
  "/vouchers/",
);
