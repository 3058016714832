import { composeRoutes, composeTabs } from "@/services/routerUtils";

const title = "Saldos do TaxiDigital";
const icon = "ticket";
const permissionTag = "vouchers";
const base = "saldosTaxiDigital";

export const titulos = {
  name: `${base}Titulos`,
  tabTitle: "Títulos a Vincular",
  path: "titulos-a-vincular",
  title,
  component: () => import("./titulos.vue"),
};

export const lancamentos = {
  name: `${base}Lancamentos`,
  tabTitle: "Lançamentos a Vincular",
  path: "lancamentos-a-vincular",
  title,
  component: () => import("./lancamentos.vue"),
};

export const manual = {
  name: `${base}Manual`,
  tabTitle: "Inserção Manual",
  path: "manual",
  title,
  component: () => import("./manual.vue"),
};

const sequence = [titulos, lancamentos, manual];

export const tabs = composeTabs(sequence);
export const navbarLinks = [{ title, icon, permissionTag, name: sequence[0].name }];
export const routes = composeRoutes([...sequence], { permissionTag }, "/saldos-taxi-digital/");
