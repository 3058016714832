import { composeRoutes } from "@/services/routerUtils";

const title = "Transferências";
const icon = "bank-transfer";
const permissionTag = "conciliacao-contabil";
const base = "transferencias";

export const relacao = {
  name: `${base}Relacao`,
  path: "relacao",
  title,
  component: () => import("./relacao.vue"),
};

export const novo = {
  name: `${base}Novo`,
  path: "novo",
  title: "Nova Transferência",
  component: () => import("./novo.vue"),
};

export const escolherTituloPagar = {
  name: `${base}Combinar`,
  path: "escolher-titulo-pagar",
  title: "Escolher Título a Pagar",
  component: () => import("./escolher-titulo-pagar.vue"),
};

export const detalhe = {
  name: `${base}Detalhe`,
  path: ":id",
  title: "Transferência",
  component: () => import("./detalhe.vue"),
};

export const navbarLinks = [{ title, icon, permissionTag, name: relacao.name }];
export const routes = composeRoutes(
  [relacao, novo, escolherTituloPagar, detalhe],
  { permissionTag },
  "/transferencias/",
);
