import { composeRoutes, composeTabs } from "@/services/routerUtils";

const title = "Extratos e Bancos";
const icon = "account_balance";
const permissionTag = "conciliacao-contabil";
const base = "extratos";

export const relacao = {
  name: `${base}Relacao`,
  tabTitle: "Relação",
  path: "relacao",
  title,
  component: () => import("./relacao.vue"),
};

export const relacaoRelatorio = {
  name: `${base}RelacaoRelatorio`,
  title: "Espelho do Extrato",
  path: "relacao/relatorio",
  component: () => import("./relacao-relatorio.vue"),
};

export const importar = {
  name: `${base}Importar`,
  tabTitle: "Importar\n Extratos",
  path: "importar",
  title,
  component: () => import("./importar.vue"),
};

export const extratosMensais = {
  name: `${base}ExtratosMensais`,
  tabTitle: "Extratos Mensais",
  path: "extratos-mensais",
  title,
  component: () => import("./extratos-mensais.vue"),
};

const sequence = [relacao, importar, extratosMensais];

export const tabs = composeTabs(sequence);
export const navbarLinks = [{ title, icon, permissionTag, name: sequence[0].name }];
export const routes = composeRoutes(
  [...sequence, relacaoRelatorio],
  { permissionTag },
  "/extratos/",
);
