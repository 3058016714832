import fecha from "fecha";
import { Big } from "big.js";

export const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(max, value));
export const pick = (
  obj: { [key: string]: any },
  keysOrFn: ((x: any) => boolean) | Array<string> = (x: any) => x !== null,
) => {
  const newObj: { [key: string]: any } = {};
  if (keysOrFn instanceof Function) {
    Object.entries(obj).forEach(([key, value]) => {
      if (keysOrFn(value)) {
        newObj[key] = value;
      }
    });
  } else {
    keysOrFn.forEach((key) => {
      if (key in obj) {
        newObj[key] = obj[key];
      }
    });
  }
  return newObj;
};

export const jsonFromForm = (form: HTMLFormElement) => {
  const result: { [key: string]: any } = {};

  for (const { name, type, value, checked, selectedOptions, parentNode } of form.elements as any) {
    if (!name) continue;

    if (type === "select-multiple" || type === "select-one") {
      for (const elm of selectedOptions) {
        result[name] = elm.value;
      }
    } else if (type === "checkbox" && checked) {
      result[name] = value;
    } else {
      if (parentNode.parentNode.classList[0] === "picker" || type === "file") {
        continue;
      }
      result[name] = value;
    }
  }

  return result;
};

const ISO_DATE = "YYYY-MM-DD";
export function toIsoDate(arrOrValue: Array<any> | Date) {
  if (!(arrOrValue instanceof Array)) {
    return fecha.format(arrOrValue, ISO_DATE);
  }
  return arrOrValue.map((d) => fecha.format(d, ISO_DATE));
}

export const debounce = (func: any, threshold: any) => {
  let timeout: any;

  return function debounceSub(this: any, ...args: Array<any>) {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (!timeout) {
      func.call(this, ...args);
    }
    const callAtEnd = timeout;
    timeout = setTimeout(() => {
      if (callAtEnd) {
        func.call(this, ...args);
      }
      timeout = null;
    }, threshold);
  };
};

export function stringify(v: string | unknown) {
  if (typeof v === "string") {
    return v;
  }
  const json = JSON.stringify(v);
  return json[0] === '"' ? json.slice(1, -1) : json;
}

export function stringifyQuery(params: Record<string, any>) {
  const esc = encodeURIComponent;
  const query = Object.entries(params)
    .filter(([, v]) => v !== undefined && v !== null)
    .map(([k, v]) => [k, stringify(v)])
    .map(([k, v]) => `${esc(k)}=${esc(v)}`)
    .join("&");

  return query ? `?${query}` : "";
}

export function simpleObjectEqual(
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>,
): boolean {
  return Object.entries(obj1)
    .filter(([key]) => key.slice(-1) !== "_")
    .every(([key, originalValue]) => {
      const newValue = obj2[key];
      if (originalValue instanceof Big && newValue instanceof Big) {
        return originalValue.eq(newValue);
      }
      if (
        typeof originalValue === "object" &&
        originalValue !== null &&
        typeof newValue === "object" &&
        newValue !== null
      ) {
        return simpleObjectEqual(
          originalValue as Record<string, unknown>,
          newValue as Record<string, unknown>,
        );
      }
      return originalValue === newValue;
    });
}

export function commonColumns(
  {
    columns,
    defaultViewColumns,
    defaultViewChildrenColumns,
  }: { defaultViewColumns: Array<any>; defaultViewChildrenColumns?: any; columns: any },
  patch: { [key: string]: any } = {},
) {
  return defaultViewColumns
    .map((id) =>
      id === "*"
        ? {
            span: defaultViewChildrenColumns.length - defaultViewColumns.length + 1,
          }
        : { id, ...columns[id] },
    )
    .map(({ id, sort, ellipsis, align, ...column }) => ({
      ...column,
      id,
      align: ellipsis ? [align, "ellipsis"] : align,
      sort: sort === null ? null : sort || id,
      ...patch[id],
    }));
}

export function commonChildrenColumns({
  childrenColumns,
  defaultViewColumns,
  defaultViewChildrenColumns,
}: {
  childrenColumns: { [key: string]: any };
  defaultViewColumns: Array<unknown>;
  defaultViewChildrenColumns: Array<string>;
}) {
  return defaultViewChildrenColumns
    .map((id) =>
      id === "*"
        ? {
            span: defaultViewColumns.length - defaultViewChildrenColumns.length + 1,
          }
        : { id, ...childrenColumns[id] },
    )
    .map(({ id, sort, ...column }) => ({
      ...column,
      id,
      sort: sort || id,
    }));
}

export function commonOptions(
  commonConfig: {
    title: string;
    reportTitle: string;
    searchUrl: string;
    errors: Record<string, any>;
    columns: Record<string, any>;
    datasetName: string | ((s: "s" | null) => string);
    trackBy?: string;

    childrenName?: string;
    childrenProp?: string;
    childrenColumns?: { [key: string]: any };
    defaultViewColumns: Array<unknown>;
    defaultViewChildrenColumns?: Array<string>;
  },
  patch?: { [key: string]: any },
) {
  return {
    reportTitle: commonConfig.reportTitle,
    searchUrl: commonConfig.searchUrl,
    errors: commonConfig.errors,
    trackBy: commonConfig.trackBy,
    columns: commonColumns(commonConfig, patch),
    datasetName: commonConfig.datasetName,

    ...(commonConfig.childrenProp && {
      childrenProp: commonConfig.childrenProp,
      childrenColumns: commonChildrenColumns(
        commonConfig as {
          childrenColumns: { [key: string]: any };
          defaultViewChildrenColumns: Array<string>;
          defaultViewColumns: Array<unknown>;
        },
      ),
      childrenName: commonConfig.childrenName,
    }),
  };
}

export function bigMin(...args: Array<number | Big>) {
  let x = new Big(args[0]);
  for (let i = 1; i < args.length; i += 1) {
    const y = new Big(args[i]);
    if (x.gt(y)) x = y;
  }
  return x;
}

export function bigMax(...args: Array<number | Big>) {
  let x = new Big(args[0]);
  for (let i = 1; i < args.length; i += 1) {
    const y = new Big(args[i]);
    if (x.lt(y)) x = y;
  }
  return x;
}
