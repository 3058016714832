<template>
  <Snackbar
    :class="messageQueue[0].class"
    :key="messageQueue[0].timestamp"
    v-if="messageQueue.length"
  >
    <span>{{ messageQueue[0].message }}</span>
  </Snackbar>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";
import { messageQueue } from "@/services/message-queue";

export default defineComponent({
  setup() {
    return {
      messageQueue,
    };
  },
});
</script>

<style lang="scss" scoped>
.lgn-snackbar.error {
  color: #ff9800;
}
</style>
