import { GetCaixa } from "common/dist/routes";
import { ref, watchEffect } from "@vue/composition-api";

import auth from "./auth";
import { api } from "./api";

const state = ref(
  undefined as
    | undefined
    | null
    | {
        id: number;
        momentoAbertura: string;
      },
);

export default state;

watchEffect(async () => {
  if (auth.value === null || !auth.value.permissoes.has("caixa")) {
    return;
  }

  try {
    const response = await api.get<GetCaixa>("caixa");

    if (response.status === 204) {
      state.value = null;
    }
    if (response.status === 200) {
      state.value = response.data;
    }
  } catch (e) {
    state.value = null;
  }
});
