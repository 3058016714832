import { composeRoutes, composeTabs } from "@/services/routerUtils";
import { routes as combinarRoutes } from "./combinar/config";
import { routes as combinarLancamentoRoutes } from "./combinar-lancamento/config";

const title = "Vinculação de Valores";
const icon = "link";
const permissionTag = "titulos-receber";
const base = "vinculacaoValores";

export const vincular = {
  name: `${base}Vincular`,
  tabTitle: "Vincular\n Valores",
  path: "vincular",
  title,
  component: () => import("./vincular.vue"),
};

export const vincularCaixa = {
  name: `${base}VincularCaixa`,
  tabTitle: "Vincular Débitos\n do Caixa",
  path: "vincular-caixa",
  title,
  component: () => import("./vincular-caixa.vue"),
};

export const classificar = {
  name: `${base}Classificar`,
  tabTitle: "Classificar\n Títulos",
  path: "classificar",
  title,
  component: () => import("./classificar.vue"),
};

const sequence = [vincular, vincularCaixa, classificar];

export const tabs = composeTabs(sequence);
export const navbarLinks = [{ title, icon, permissionTag, name: sequence[0].name }];
export const routes = composeRoutes(
  [...sequence, ...combinarRoutes, ...combinarLancamentoRoutes],
  { permissionTag },
  "/vinculacao-valores/",
);
