import { jsonFromForm, stringifyQuery } from "./utils";
import auth, { checkToken } from "./auth";

class BadRequestException {
  message: string;

  status: number;

  data: any;

  constructor(status: number, data: any) {
    this.message = "Entrada inválida";
    this.status = status;
    this.data = data;
  }
}
(BadRequestException.prototype as any).toString = function toString() {
  return `${this.status} ${this.message}`;
};

class ServerException {
  message: string;

  status: number;

  data: any;

  constructor(status: number, data: any) {
    this.message = "Ocorreu um erro ao atender seu pedido";
    this.status = status;
    this.data = data;
  }
}
(ServerException.prototype as any).toString = function toString() {
  return `${this.status} ${this.message}`;
};

const contentByHeader = (res: any, options: any) => {
  const contentType = res.headers.get("Content-Type");
  if (contentType && contentType.indexOf("application/json") !== -1 && options.method !== "HEAD") {
    return res.json();
  }
  if (contentType && contentType.indexOf("application/") !== -1) {
    return res.blob();
  }
  return res.text();
};

export type API = {
  Method: "GET" | "POST" | "PUT";
  URL: string;
  Response: unknown;
};

export const api = {
  prefix: "/api/f8/",

  async fetch<T extends API>(
    uri: T["URL"],
    options: { [key: string]: any } = {},
  ): Promise<{
    status: number;
    headers: Headers;
    data: T["Response"];
  }> {
    if (!(await checkToken())) {
      // Here we just return since the user will be logged out
      throw new Error("No token");
    }

    const res = await fetch(this.prefix + uri, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${options.token || auth.value?.token}`,
      },
    });

    if (res.status >= 500 && res.status < 600) {
      throw new ServerException(res.status, await contentByHeader(res, options));
    }

    if (res.status >= 400 && res.status <= 499) {
      throw new BadRequestException(res.status, await contentByHeader(res, options));
    }

    if (res.status === 204) {
      return { status: res.status, headers: res.headers, data: null as any };
    }
    return { status: res.status, headers: res.headers, data: await contentByHeader(res, options) };
  },
  async get<T extends API & { Method: "GET" }>(
    uri: T["URL"],
    ...paramsArr: Array<HTMLFormElement | { [key: string]: any }>
  ) {
    return this.query<T>("GET", uri, paramsArr);
  },
  async head(uri: string, ...paramsArr: Array<HTMLFormElement | { [key: string]: any }>) {
    return this.query("HEAD", uri, paramsArr);
  },
  async getSignal(
    uri: string,
    signal: any,
    ...paramsArr: Array<HTMLFormElement | { [key: string]: any }>
  ) {
    return this.query("GET", uri, paramsArr, { signal });
  },
  async del(uri: string, ...paramsArr: Array<HTMLFormElement | { [key: string]: any }>) {
    return this.query("DELETE", uri, paramsArr);
  },
  async query<T extends API>(
    method: string,
    url: string,
    paramsArr: Array<HTMLFormElement | { [key: string]: any }>,
    extraOptions: { [key: string]: any } | undefined = undefined,
  ) {
    const allParams: { [key: string]: string } = paramsArr
      .map((param) => (param instanceof HTMLFormElement ? jsonFromForm(param) : param))
      .reduce((acc, it) => Object.assign(acc, it), Object.create(null));

    const queryParams = Object.create(null);
    for (const key in allParams) {
      if (key[0] === ":") {
        url = url.replace(key, allParams[key]);
      } else if (key[0] === "?") {
        queryParams[key.slice(1)] = allParams[key];
      } else {
        queryParams[key] = allParams[key];
      }
    }

    return this.fetch<T>(url + stringifyQuery(queryParams), { method, ...extraOptions });
  },
  async body<T extends API>(
    method: string,
    uri: string,
    bodyArr: Array<HTMLFormElement | any | Array<any>>,
    extraOptions: { [key: string]: any } | undefined = undefined,
  ) {
    const body = bodyArr.every((el) => el instanceof Array)
      ? bodyArr.reduce((x: Array<any>, y) => x.concat(y), [])
      : bodyArr
          .map((param) => (param instanceof HTMLFormElement ? jsonFromForm(param) : param))
          .reduce((acc, it) => Object.assign(acc, it), {});

    return this.fetch<T>(uri, {
      ...extraOptions,
      method,
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...(extraOptions && extraOptions.headers),
      },
    });
  },
  async postFile(uri: string, file: FormData) {
    return this.fetch(uri, {
      method: "POST",
      body: file,
    });
  },
  async post<T extends API & { Method: "POST" }>(
    uri: string,
    ...bodyArr: Array<HTMLFormElement | any | Array<any>>
  ) {
    return this.body<T>("POST", uri, bodyArr);
  },
  async put(uri: string, ...bodyArr: Array<HTMLFormElement | any | Array<any>>) {
    return this.body("PUT", uri, bodyArr);
  },
};

export const oapi = { ...api, prefix: "/f8/api/" };
