<template>
  <div id="app">
    <Transition name="slow-fade">
      <div v-if="processing" class="loading">
        <LoadingPulse />
        <div class="text-processing">Processando...</div>
      </div>
    </Transition>
    <SecurityWarning />
    <TheTitleBar :tabbed="tabbed" @reload="reload" @toggle-menu="toggleMenu">
      <slot name="title">
        <h1>{{ computedTitle }}</h1>
      </slot>
    </TheTitleBar>
    <div id="pre-content">
      <TheNavbar :force-menu.sync="forceMenu" />
      <div id="content">
        <slot />
      </div>
    </div>
    <TheSnackbarQueueConsumer />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "@vue/composition-api";
import useRouter from "@/services/useRouter";
import TheNavbar from "@/components/TheNavbar.vue";
import TheTitleBar from "@/components/TheTitleBar.vue";
import TheSnackbarQueueConsumer from "@/components/TheSnackbarQueueConsumer.vue";
import SecurityWarning from "@/components/SecurityWarning.vue";

export default defineComponent({
  props: {
    title: String,
    tabbed: Boolean,
    processing: Boolean,
  },
  setup(props, { emit }) {
    const { route } = useRouter();
    const forceMenu = ref(false);
    return {
      reload() {
        emit("reload");
      },
      computedTitle: computed(() => {
        return props.title || route.value.meta?.title;
      }),
      forceMenu,
      toggleMenu() {
        forceMenu.value = !forceMenu.value;
      },
    };
  },
  components: {
    TheNavbar,
    TheTitleBar,
    SecurityWarning,
    TheSnackbarQueueConsumer,
  },
});
</script>

<style lang="scss" scoped>
#app {
  display: flex;
  height: 100%;
  flex-flow: column;
}

#content {
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow: auto;
}

#pre-content {
  display: flex;
  flex-flow: row;
  flex: 1;
  overflow: auto;
}

.loading {
  position: absolute;
  z-index: 100000;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
}

.slow-fade-enter-active,
.slow-fade-leave-active {
  transition: opacity 0.8s;
  transition-delay: 0.9s;
}
.slow-fade-enter,
.slow-fade-leave-to {
  opacity: 0;
}

.text-processing {
  margin-top: 8px;
  color: white;
  font-weight: 500;
  font-size: 18px;
}
</style>

<style lang="scss">
@media print {
  @page {
    margin: 0;
  }

  .no-print,
  .tabwrapper {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }
}

.print-only {
  display: none;
}

.search.hide-helper {
  margin: 4px;
  flex: 1;
}
</style>
