import { composeRoutes, composeTabs } from "@/services/routerUtils";

const title = "Conciliação Contábil";
const icon = "scale-balance";
const permissionTag = "conciliacao-contabil";
const base = "conciliacaoContabil";

export const regrasContabeis = {
  name: `${base}RegrasContabeisRelacao`,
  tabTitle: "Regras\n Contábeis",
  path: "regras-contabeis",
  title,
  component: () => import("./regras-contabeis.vue"),
};

export const importarContabeis = {
  name: `${base}ImportarContabeis`,
  path: "importar-contabeis",
  title,
  tabTitle: "Importar Contas da Contabilidade",
  component: () => import("./importar-contabeis.vue"),
};

const sequence = [regrasContabeis, importarContabeis];

export const tabs = composeTabs(sequence);
export const navbarLinks = [{ title, icon, permissionTag, name: sequence[0].name }];
export const routes = composeRoutes(sequence, { permissionTag }, "/conciliacao-contabil/");
