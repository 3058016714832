import { composeRoutes } from "@/services/routerUtils";

const permissionTag = "rateios";
const base = "rateios";

export const detalhe = {
  name: `${base}Detalhe`,
  path: ":id",
  title: "Rateio",
  component: () => import("./detalhe.vue"),
};

export const relatorio = {
  name: `${base}relatorio`,
  title: "Relatório do Rateio",
  path: ":id/relatorio",
  component: () => import("./relatorio.vue"),
};

export const itemRateio = {
  name: `${base}ItemRateioDetalhe`,
  path: "itens/:id",
  title: "Item do Rateio",
  component: () => import("./item-rateio.vue"),
};

export const itemRateioRelatorio = {
  name: `${base}ItemRateioRelatorio`,
  title: "Relatório do Item do Rateio",
  path: "itens/:id/relatorio",
  component: () => import("./item-rateio-relatorio.vue"),
};

export const routes = composeRoutes(
  [detalhe, itemRateio, itemRateioRelatorio, relatorio],
  { permissionTag },
  "",
);
