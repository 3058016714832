<template>
  <div class="search-form" v-if="fields">
    <Field
      v-for="field in fields.filter((x) => !x.hidden)"
      :key="field.id"
      hide-helper
      :value="value[field.id]"
      @input="(text) => updateValue(field.id, text)"
      v-bind="field"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";
import Field from "./Field.vue";

export default defineComponent({
  components: { Field },

  props: {
    fields: Array,
    value: Object,
  },

  setup(props, { emit }) {
    return {
      updateValue(id: string, value: any) {
        emit("input", { ...props.value, [id]: value });
      },
    };
  },
});
</script>
