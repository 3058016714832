<template>
  <Transition name="lgn-fade">
    <div class="security-warning" v-if="passwordWarning">
      <span class="inner">
        <Icon name="warning" />
        A sua senha precisa de atualização, por favor troque-a acessando
        <RouterLink replace :to="perfilRoute">o seu perfil</RouterLink>
      </span>
      <TextButton @click="updatePasswordWarning(false)">Dispensar</TextButton>
    </div>
  </Transition>
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";

import { passwordWarning } from "@/services/auth";
import { perfil as perfilRoute } from "@/pages/config";

export default defineComponent({
  setup() {
    return {
      passwordWarning,
      updatePasswordWarning(newValue: boolean) {
        passwordWarning.value = newValue;
      },
      perfilRoute,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "~ligno/src/shadows";

.security-warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  background: #ffc107;
  display: flex;
  flex-flow: column;
  padding: 16px;
  color: #000;
  fill: #000;
  box-shadow: $whiteframe-shadow-3dp;
  > span {
    display: inline-flex;
    white-space: pre;
    justify-content: center;
    align-items: center;
    padding: 8px;
    flex: 1;
    > svg {
      margin-right: 8px;
    }
  }
  > .lgn-textbutton {
    background: #ffc107;
    justify-self: end;
  }
}
</style>
