<template>
  <ProcessScreen
    :fields="fields"
    :filter="filter"
    v-bind="$attrs"
    v-on="$listeners"
    ref="processScreen"
  >
    <template v-for="(slot, key) in $scopedSlots" v-slot:[key]="slotProps">
      <slot v-bind="slotProps" :name="key" />
    </template>
    <template #search>
      <div class="filters">
        <template
          v-for="{ id, label, options, control, type } in fields.filter(
            ({ id, type }) => filter[id] !== undefined && id !== 'q' && type !== 'password',
          )"
        >
          <div class="chip" :key="id">
            <span class="label">
              {{ label }}
            </span>
            <Dropdown
              v-if="options"
              @input="(v) => updateFilter({ [id]: v })"
              :value="filter[id]"
              inline
              :options="options"
            />
            <Picker
              @input="(v) => updateFilter({ [id]: v })"
              :value="filter[id]"
              v-else-if="
                control === 'picker' ||
                type === Date ||
                (type instanceof Array && type.every((d) => d === Date))
              "
              :type="type instanceof Array ? '*d' : typeof type === 'function' ? 'd' : type"
              inline
            />
            <TextField
              v-else
              @input="(v) => updateFilter({ [id]: v })"
              :value="filter[id]"
              inline
            />
            <IconButton @click="$set(filter, id, undefined)" class="remove-button">
              <Icon name="clear" />
            </IconButton>
          </div>
          e
        </template>
        <div class="chip">
          <Dropdown
            inline
            :options="
              fields.filter(({ id }) => id != 'q').map(({ id, label }) => ({ id, text: label }))
            "
            @input="
              (id) => {
                $set(filter, id, null);
              }
            "
          />
        </div>
        <div class="spacer"></div>
        <Popover
          @blur="columnsOpened = false"
          tabindex="0"
          ref="columnsPopover"
          v-model="columnsOpened"
        >
          <TextButton @click="columnsClickOpen" class="customize-columns">
            Colunas
            <div class="lgn-triangle" />
          </TextButton>
          <template #popper>
            <div class="popper">
              <template v-for="(column, id) in allColumns">
                <div class="option" :key="id">
                  <Checkbox
                    @input="(value) => onChange(value, id)"
                    :value="+!!selectedColumns.find((x) => x === id)"
                    >{{ column.name }}</Checkbox
                  >
                </div>
              </template>
            </div>
          </template>
        </Popover>
      </div>
    </template>
  </ProcessScreen>
</template>

<script lang="ts">
import { computed, ref, defineComponent, nextTick } from "@vue/composition-api";

export default defineComponent({
  inheritAttrs: false,
  props: {
    fields: null,
    selectedColumns: null,
    allColumns: null,
    filter: null,
  },
  model: { prop: "dataset", event: "update:dataset" },
  setup(props, { emit }) {
    const processScreen = ref(
      null as null | {
        search: () => Promise<void>;
        computedQueryString: string;
      },
    );
    const columnsOpened = ref(false);
    const columnsPopover = ref(
      null as null | {
        $el: HTMLElement;
      },
    );
    return {
      onChange(value: number, id: string) {
        emit(
          "update:selected-columns",
          Object.keys(props.allColumns).filter(
            (c) =>
              (value === 1 && c === id) ||
              (props.selectedColumns.find((s: string) => s === c) &&
                (value === 1 || (value === 0 && c !== id))),
          ),
        );
      },
      columnsOpened,
      columnsPopover,
      processScreen,
      updateFilter(newFilter: Record<string, unknown>) {
        emit("update:filter", {
          ...props.filter,
          ...newFilter,
        });
      },
      search() {
        processScreen.value?.search();
      },
      computedQueryString: computed(() => {
        return processScreen.value?.computedQueryString;
      }),
      async columnsClickOpen() {
        columnsOpened.value = !columnsOpened.value;
        if (columnsOpened.value) {
          await nextTick();
          columnsPopover.value?.$el.focus();
        }
      },
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/colors";

.chip {
  display: flex;
  align-items: center;
  background: #ddd;
  color: #333;
  border-radius: 16px;
  padding: 8px;
  height: 32px;
  margin: 4px;
}

.filters {
  display: flex;
  padding: 8px;
  align-items: center;
  flex-wrap: wrap;
  background: get-primary-color();
  color: #fff;
}

.label {
  margin-right: 4px;
}

.remove-button {
  display: inline;
  padding: 0;
  height: 24px;
  width: 24px;
  margin-left: 4px;
  margin-right: -4px;

  ::v-deep svg {
    width: 20px;
    height: 20px;
  }
}

.popper {
  color: #000;
}
.option > .lgn-checkbox {
  padding: 8px;
}

.customize-columns {
  color: #fff;
  &:hover,
  &:focus {
    background: get-primary-color();
  }
}
</style>
