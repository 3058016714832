export function percen(value: string | number) {
  if (!+value) {
    return "—";
  }
  return `${value.toString().replace(".", ",")}%`;
}

export function sum(
  value: Array<string | number | Record<string, string | number>>,
  map: null | Array<number> | string,
) {
  if (!value) {
    return value;
  }

  if (map instanceof Array) {
    value = map.map((x) => value[x]);
  } else if (map) {
    value = value.map((x) => (x as Record<string, string | number>)[map]);
  }

  return value.reduce((x, y) => +x + +y, 0);
}
