export function selectionCount(
  value: Array<unknown> | number,
  name: ((input: string) => string) | string,
  gender?: string,
  pluralName?: string,
) {
  if (value instanceof Array) {
    value = value.length;
  }

  const singularName = typeof name === "function" ? name("") : name;
  const genderGuess = gender || (singularName.slice(-1) === "a" ? "a" : "");
  const none = genderGuess === "a" ? "Nenhuma" : "Nenhum";
  const selected = genderGuess === "a" ? "selecionada" : "selecionado";
  const pluralizedName =
    pluralName ||
    (typeof name === "function"
      ? name("s")
      : `${name.split(" ")[0]}s ${name.split(" ").slice(1).join(" ")}`.trim());

  if (value === 0) {
    return `${none} ${singularName} ${selected}`;
  }
  if (value === 1) {
    return `1 ${singularName} ${selected}`;
  }
  return `${value} ${pluralizedName} ${selected}s`;
}

export function s(value: Array<unknown> | number) {
  if (value instanceof Array) {
    value = value.length;
  }
  return value !== 1 ? "s" : "";
}
