<template>
  <img
    class="f8-avatar"
    :src="`/api/f8/avatar/${usuario}.svg`"
    :style="{ 'min-width': size + 'px', width: size + 'px', height: size + 'px' }"
    alt=""
  />
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    usuario: String,
    size: { type: Number, default: 64 },
  },
});
</script>

<style lang="scss" scoped>
img {
  border-radius: 50%;
  background-color: #fff;
}
</style>
