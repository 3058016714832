import { composeRoutes } from "@/services/routerUtils";

const title = "Notas de Entrada";
const icon = "basket";
const permissionTag = "caixa";
const base = "notasEntrada";

export const relacao = {
  name: `${base}Relacao`,
  path: "relacao",
  title,
  component: () => import("./relacao.vue"),
};

export const detalhe = {
  name: `${base}Detalhe`,
  path: ":ano/:numero",
  title: "Nota de Entrada",
  component: () => import("./detalhe.vue"),
};

export const novo = {
  name: `${base}Novo`,
  path: "novo",
  title: "Nova Nota de Entrada",
  component: () => import("./novo.vue"),
};

export const navbarLinks = [{ title, icon, permissionTag, name: relacao.name }];
export const routes = composeRoutes([relacao, detalhe, novo], { permissionTag }, "/notas-entrada/");
