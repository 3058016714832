import { watchEffect, ref } from "@vue/composition-api";

export const messageQueue = ref(
  [] as Array<{
    timestamp: number;
    message: string;
    class?: string;
  }>,
);

export function queueMessage(message: { message: string; class?: string }) {
  if (messageQueue.value.length && messageQueue.value.slice(-1)[0].message === message.message) {
    return;
  }

  messageQueue.value.push({ timestamp: +new Date(), ...message });
}

export function unqueueMessage() {
  messageQueue.value.splice(0, 1);
}

export function queueError(error: Error & { data?: { errors?: Array<{ message: string }> } }) {
  queueMessage({
    message:
      error.data && error.data.errors && error.data.errors.length
        ? error.data.errors.map((x) => x.message).join("\n")
        : error.message,
    class: "error",
  });
}

function unqueue() {
  unqueueMessage();
  if (messageQueue.value.length) {
    setTimeout(unqueue, 5000);
  }
}

watchEffect(() => {
  if (messageQueue.value.length) {
    setTimeout(unqueue, 5000);
  }
});
