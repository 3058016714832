import { composeRoutes, composeTabs } from "@/services/routerUtils";

const title = "Fluxo de Caixa";
const icon = "transfer";
const permissionTag = "caixa";
const base = "fluxoCaixa";

export const geral = {
  name: `${base}Geral`,
  tabTitle: "Geral",
  path: "geral",
  title,
  component: () => import("./geral.vue"),
};

export const relacao = {
  name: `${base}Relacao`,
  tabTitle: "Relação",
  path: "relacao",
  title,
  component: () => import("./relacao.vue"),
};

export const lancar = {
  name: `${base}Lancar`,
  tabTitle: "Lançar",
  path: "lancar",
  title,
  component: () => import("./lancar.vue"),
};

const sequence = [geral, relacao, lancar];

export const tabs = composeTabs(sequence);
export const navbarLinks = [
  {
    title,
    icon,
    permissionTag,
    name: sequence[0].name,
  },
];
export const routes = composeRoutes(sequence, { permissionTag }, "/fluxo-caixa/");
