<template functional>
  <img :src="`/api/f8/avatar/${props.username}.svg`" width="16" />
</template>

<script lang="ts">
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: { username: String },
});
</script>

<style lang="scss" scoped>
img {
  vertical-align: middle;
  border-radius: 50%;
}
</style>
