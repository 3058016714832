import { ref, Ref } from "@vue/composition-api";
import { Route } from "vue-router";
import router from "@/router";

let route: Ref<Route> | null = null;

window.addEventListener("popstate", (e) => {
  if (e.state !== null && "sentinel" in e.state) {
    if (route?.value?.params.back) {
      router.push(route.value.params.back);
    } else {
      e.stopImmediatePropagation();
      window.history.go(1);
    }
  }
});

export default function useRouter() {
  if (route === null) {
    route = ref(router.currentRoute) as Ref<Route>;
    router.afterEach((to: Route) => {
      (route as Ref<Route>).value = to;
    });
  }

  return {
    route: route as Ref<Route>,
    router,
  };
}
