var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.auth)?_c('div',{staticClass:"navbar"},[(_vm.forceMenu)?_c('div',{staticClass:"backdrop",on:{"click":function($event){return _vm.$emit('update:force-menu', false)}}}):_vm._e(),_c('nav',{class:{ force: _vm.forceMenu }},[_c('TextField',{staticClass:"search-menu outlined",attrs:{"label":"Pesquisar Menu...","hide-helper":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{ref:"linksDiv",staticClass:"links"},[_vm._l((_vm.links),function(ref){
var name = ref.name;
var title = ref.title;
var icon = ref.icon;
var sublinks = ref.sublinks;
var group = ref.group;
return [_c('RouterLink',{directives:[{name:"waves",rawName:"v-waves"}],key:name,staticClass:"menu-item waves-effect",attrs:{"replace":"","to":{ name: name }}},[_c('Icon',{attrs:{"name":icon}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(title.value ? title.value : title))]),(sublinks)?_c('IconButton',{staticClass:"expand-button",class:_vm.expandedGroup === group && 'inverted',on:{"click":function($event){$event.stopPropagation();_vm.expandedGroup = _vm.expandedGroup !== group ? group : null}}},[_c('Icon',{attrs:{"name":"expand"}})],1):_vm._e()],1),_vm._l((sublinks),function(ref){
var partOf = ref.partOf;
var name = ref.name;
var icon = ref.icon;
var title = ref.title;
return [(_vm.expandedGroup === partOf)?_c('RouterLink',{directives:[{name:"waves",rawName:"v-waves"}],key:name,staticClass:"menu-item waves-effect",attrs:{"replace":"","to":{ name: name }}},[(icon)?_c('Icon',{key:name + '/icon',attrs:{"name":icon}}):_vm._e(),_c('div',{key:name,staticClass:"sub-title"},[_vm._v(_vm._s(title))])],1):_vm._e()]})]})],2)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }