import { composeRoutes } from "@/services/routerUtils";

const title = "Cruzamentos";
const icon = "routes";
const permissionTag = "cruzamentos";
const base = "cruzamentos";

export const cruzamentos = {
  name: base,
  title,
  path: "",
  component: () => import("./main.vue"),
};

export const navbarLinks = [{ title, icon, permissionTag, name: cruzamentos.name }];
export const routes = composeRoutes([cruzamentos], { permissionTag }, "/cruzamentos/");
