import { composeRoutes, composeTabs } from "@/services/routerUtils";

const title = "Fechamentos";
const icon = "handshake";
const permissionTag = "fechamentos";
const base = "fechamentos";

export const processar = {
  name: `${base}Processar`,
  tabTitle: "Processar\n Vouchers",
  path: "processar",
  title,
  component: () => import("./processar.vue"),
};

export const processarRelatorioVouchers = {
  name: `${base}ProcessarRelatorioVouchers`,
  path: "processar/relatorio-vouchers",
  title: "Relação de Vouchers",
  component: () => import("./processar-relatorio-vouchers.vue"),
};

export const gerar = {
  name: `${base}Gerar`,
  tabTitle: "Gerar\n RPS",
  path: "gerar",
  title,
  component: () => import("./gerar.vue"),
};

export const gerarRelatorioVouchers = {
  name: `${base}GerarRelatorioVouchers`,
  path: "gerar/relatorio-vouchers",
  title: "Relatório de Vouchers de Fechamentos",
  component: () => import("./gerar-relatorio-vouchers.vue"),
};

export const enviar = {
  name: `${base}Enviar`,
  tabTitle: "Arquivos RPS\n A Enviar",
  path: "enviar",
  title,
  component: () => import("./enviar.vue"),
};

export const importar = {
  name: `${base}Importar`,
  tabTitle: "Importar\n Arquivos RPS",
  path: "importar",
  title,
  component: () => import("./importar.vue"),
};

export const notasFiscais = {
  name: `${base}NotasFiscais`,
  tabTitle: "Notas\n Fiscais",
  path: "notas-fiscais",
  title,
  component: () => import("./notas-fiscais.vue"),
};

export const notasFiscaisRelatorio = {
  name: `${base}NotasFiscaisRelatorio`,
  path: "notas-fiscais/relatorio",
  title: "Relatório de Notas Fiscais",
  component: () => import("./notas-fiscais-relatorio.vue"),
};

export const notasFiscaisRelatorioVouchers = {
  name: `${base}NotasFiscaisRelatorioVouchers`,
  path: "notas-fiscais/relatorio-vouchers",
  title: "Relatório com Vouchers de Notas Fiscais",
  component: () => import("./notas-fiscais-relatorio-vouchers.vue"),
};

const sequence = [processar, gerar, enviar, importar, notasFiscais];

export const tabs = composeTabs(sequence);
export const navbarLinks = [{ title, icon, permissionTag, name: sequence[0].name }];
export const routes = composeRoutes(
  [
    ...sequence,
    processarRelatorioVouchers,
    gerarRelatorioVouchers,
    notasFiscaisRelatorio,
    notasFiscaisRelatorioVouchers,
  ],
  { permissionTag },
  "/fechamentos/",
);
