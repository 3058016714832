import { computed, ref, watch } from "@vue/composition-api";
import router from "@/router";

let fetchedState = null;

try {
  const fetchedPermissoesOrgaos = localStorage.getItem("permissoesOrgaos");
  const fetchedTokens = localStorage.getItem("tokens");
  const fetchedIdOrgao = localStorage.getItem("idOrgao");
  const fetchedIdSetor = localStorage.getItem("idSetor");
  const fetchedOrgaos = localStorage.getItem("orgaos");
  const fetchedF8 = localStorage.getItem("f8");

  if (
    fetchedPermissoesOrgaos !== null &&
    fetchedTokens !== null &&
    fetchedIdOrgao !== null &&
    fetchedIdSetor !== null &&
    fetchedOrgaos !== null &&
    fetchedF8 !== null
  ) {
    fetchedState = {
      tokens: JSON.parse(fetchedTokens),
      usuario: localStorage.getItem("usuario"),
      permissoesOrgaos: Object.fromEntries(
        Object.entries(
          JSON.parse(fetchedPermissoesOrgaos) as { [idOrgao: number]: Array<string> },
        ).map(([k, v]) => [k, new Set(v)]),
      ),
      orgaos: JSON.parse(fetchedOrgaos),
      nome: localStorage.getItem("nome"),
      setor: localStorage.getItem("setor"),
      idSetor: +fetchedIdSetor,
      idOrgao: +fetchedIdOrgao,
      f8: JSON.parse(fetchedF8),
    };
  }
} catch (e) {
  fetchedState = null;
}
const state = ref(fetchedState);

watch(
  () => state.value,
  () => {
    document.documentElement.className = `theme-${state.value?.idOrgao ?? 0}`;
  },
  { immediate: true },
);

const stateWithToken = computed(() => {
  const stateValue = state.value;
  if (!stateValue) {
    return null;
  }
  return {
    usuario: stateValue.usuario,
    nome: stateValue.usuario,
    setor: stateValue.setor,
    idSetor: stateValue.idSetor,
    idOrgao: stateValue.idOrgao,
    permissoes: stateValue.permissoesOrgaos[stateValue.idOrgao],
    permissoesOrgaos: stateValue.permissoesOrgaos,
    orgaos: stateValue.orgaos,
    token: stateValue.tokens[stateValue.idOrgao],
    f8Token: stateValue.f8?.tokens[stateValue.idOrgao],
  };
});

export default stateWithToken;

export const passwordWarning = ref(false);

export async function logout() {
  localStorage.clear();
  state.value = null;
}

export function verifyToken() {
  if (stateWithToken.value?.token) {
    const { token } = stateWithToken.value;
    try {
      const data = JSON.parse(window.atob(token.split(".")[1]));
      const expirationDate = data.exp * 1000;
      const difference = expirationDate - new Date().getTime();

      // Success
      if (difference > 0) {
        return;
      }
    } catch (e) {
      // oh well
    }
  }

  logout();
}

function setStateFromJsonData(
  jsonData: {
    permissoesOrgaos: { [idOrgao: number]: Array<string> };
    orgaos: Array<number>;
    tokens: { [idOrgao: number]: string };
    setor: string;
    idOrgao: number;
    idSetor: number;
    nome: string;
  },
  usuario?: string,
) {
  state.value = {
    ...jsonData,
    permissoesOrgaos: Object.fromEntries(
      Object.entries(jsonData.permissoesOrgaos as { [idOrgao: number]: Array<string> }).map(
        ([k, v]) => [k, new Set(v)],
      ),
    ),
    usuario: usuario ?? state.value?.usuario,
  };

  try {
    localStorage.setItem("tokens", JSON.stringify(jsonData.tokens));
    if (usuario) {
      localStorage.setItem("usuario", usuario);
    }
    localStorage.setItem("permissoesOrgaos", JSON.stringify(jsonData.permissoesOrgaos));
    localStorage.setItem("nome", jsonData.nome);
    localStorage.setItem("orgaos", JSON.stringify(jsonData.orgaos));
    localStorage.setItem("setor", jsonData.setor);
    localStorage.setItem("idSetor", jsonData.idSetor.toString());
    localStorage.setItem("idOrgao", jsonData.idOrgao.toString());
    localStorage.setItem("f8", JSON.stringify(jsonData.f8));
  } catch (e) {
    // we can't do much if we fail
  }
}

export async function login({ usuario, senha }: { usuario: string; senha: string }) {
  const res = await fetch("/api/f8/logins/", {
    method: "POST",
    body: JSON.stringify({ usuario, senha }),
    headers: { "Content-Type": "application/json" },
  });

  if (res.status !== 200) {
    return res.status;
  }

  const jsonData = await res.json();
  setStateFromJsonData(jsonData, usuario);

  if (senha === "1234") {
    passwordWarning.value = true;
  }

  return 200;
}

export async function checkToken() {
  try {
    if (!stateWithToken.value?.token) {
      throw new Error("No token");
    }
    const data = JSON.parse(window.atob(stateWithToken.value.token.split(".")[1]));
    const expirationDate = data.exp * 1000;
    const difference = expirationDate - new Date().getTime();

    if (difference < 0) {
      throw new Error("Expired token");
    } else if (difference < 6 * 60 * 60 * 1000) {
      const res = await fetch("/api/f8/token", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${stateWithToken.value.token}`,
        },
      });

      if (res.status !== 200) {
        throw new Error("Failed to fetch token");
      }

      const jsonData = await res.json();
      setStateFromJsonData(jsonData);
    }

    return true;
  } catch (err) {
    logout();
    if (router.currentRoute.name !== "login") {
      router.replace("/login");
    }
    return false;
  }
}

export async function setIdOrgao(idOrgao: number) {
  if (state.value) {
    state.value = { ...state.value, idOrgao };
    localStorage.setItem("idOrgao", idOrgao.toString());
  }
}

verifyToken();
