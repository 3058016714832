<template>
  <LMap
    ref="map"
    class="map"
    :center.sync="center"
    :zoom.sync="zoom"
    :options="{ zoomControl: false }"
  >
    <LTileLayer :url="url" />
    <LControlZoom position="bottomleft" />
    <slot />
  </LMap>
</template>

<script lang="ts">
import "leaflet/dist/leaflet.css";

import { defineComponent, ref } from "@vue/composition-api";
import { LMap, LControlZoom, LTileLayer } from "vue2-leaflet";
import { LatLngExpression, ZoomPanOptions, Popup } from "leaflet";

import { mapbox } from "@/services/secrets.json";

export default defineComponent({
  components: {
    LMap,
    LControlZoom,
    LTileLayer,
  },

  setup() {
    const zoom = ref(12);
    const center = ref([-25.429716, -49.271917]);
    const map = ref(null as null | LMap);

    return {
      url: `https://api.mapbox.com/styles/v1/${mapbox.mapStyle.slice(
        16,
      )}/tiles/256/{z}/{x}/{y}?access_token=${mapbox.accessToken}`,
      center,
      zoom,
      map,
      flyTo(latlng: LatLngExpression, flyZoom?: number, options?: ZoomPanOptions) {
        const mapValue = map.value;
        if (mapValue) mapValue.mapObject.flyTo(latlng, flyZoom, options);
      },
      openPopup(popup: Popup) {
        const mapValue = map.value;
        if (mapValue) mapValue.mapObject.openPopup(popup);
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.map {
  height: 100%;
  width: 100%;
}
</style>
